import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  AssignReasonToDowntimeEntry,
  Downtime,
  DowntimeReason,
  DowntimeSection,
  DowntimesService,
  WorkCenterService,
  Quantity,
  AssignReasonToDowntimeBehaviourType
} from 'chronos-core-client';
import { ListValue } from 'chronos-shared';
import { DowntimeViewData } from '@app/shared/modals/task-assign-modal/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaskDsService {
  constructor(
    private downtimesService: DowntimesService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private workCenterService: WorkCenterService
  ) {}

  public getCombinedDowntimeData(workCenterId?: number): Observable<DowntimeViewData> {
    const currentWorkCenterId = workCenterId || this.activeWorkCenterService.getWorkCenterId();
    const requestWasteReasonList = this.getWasteReasons(currentWorkCenterId);
    const requestReasonTreeList = this.getDowntimeReasonsTreeList(currentWorkCenterId);
    const requestReasonFlatList = this.getDowntimeReasonsFlatList(currentWorkCenterId);

    return combineLatest([requestWasteReasonList, requestReasonTreeList, requestReasonFlatList]).pipe(
      map(([wasteReasonList, reasonTreeList, reasonFlatList]) => ({
        wasteReasonList,
        reasonTreeList,
        reasonFlatList
      }))
    );
  }

  public setDowntimeReason(
    downtimeId: number,
    reasonId: number,
    info: string,
    maculature: Quantity,
    waste: Quantity,
    wasteReasonId: number,
    workCenterId?: number
  ): Observable<void> {
    const currentWorkCenterId = workCenterId || this.activeWorkCenterService.getWorkCenterId();
    return this.downtimesService.assignReasonToDowntime({
      downtimeId,
      body: {
        reasonId,
        info,
        workCenterId: currentWorkCenterId,
        wasteReasonId,
        maculature,
        waste,
        behaviourType: AssignReasonToDowntimeBehaviourType.ShiftReport
      }
    });
  }

  public setMultipleDownTime(
    workCenterId,
    downtimeIds: number[],
    assignReasonToDowntimeEntry: AssignReasonToDowntimeEntry,
    behaviourType: AssignReasonToDowntimeBehaviourType
  ): Observable<void> {
    return this.downtimesService.assignMultipleReasonsToDowntime({
      body: {
        workCenterId,
        assignReasonToDowntimeEntry,
        downtimeIds,
        behaviourType
      }
    });
  }

  public convertToProduction(downtimeId: number, workCenterId?: number): Observable<void> {
    const currentWorkCenterId = workCenterId || this.activeWorkCenterService.getWorkCenterId();
    return this.downtimesService.convertToProduction({
      downtimeId,
      body: {
        workCenterId: currentWorkCenterId
      }
    });
  }

  public splitDowntime(downtimeId: number, splitTime: string): Observable<void> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.downtimesService.splitDowntime({
      downtimeId,
      body: {
        workCenterId,
        splitTime
      }
    });
  }

  public getWasteReasons(workCenterId: number): Observable<ListValue[]> {
    return this.workCenterService.getWasteReasons({ workCenterId });
  }

  public getDowntime(downtimeId: number): Observable<Downtime> {
    return this.downtimesService.getDowntime({ downtimeId });
  }

  public getDowntimeReasonsFlatList(workCenterId: number): Observable<DowntimeReason[]> {
    return this.downtimesService.getReasonsFlatList({ workCenterId });
  }

  public getDowntimeReasonsTreeList(workCenterId: number): Observable<DowntimeSection[]> {
    return this.downtimesService.getReasonsTreeList({ workCenterId });
  }
}
