<ng-container *ngIf="tableData && tableData.length > 0; else noDataTemplate">
  <section class="approve-report-table">
    <p-treeTable
      [styleClass]="'approve-report-tree-table'"
      [value]="tableData"
      (onNodeExpand)="loadNode($event)"
      [scrollable]="true"
      [scrollHeight]="'flex'"
      [style]="{ width: 'calc(100vw - 128px)', height: 'calc(100vh - 350px)' }"
    >
      <ng-template pTemplate="header">
        <tr class="approve-report-table__header-row">
          <th class="toggle-cell sticky-column">
            <i
              class="toggle-cell__icon cursor-clickable"
              [ngClass]="tableExpanded ? 'icon-collapse-all' : 'icon-expand-all'"
              (click)="expandCollapseTable()"
            ></i>
          </th>
          <th
            (click)="onHighlight(col)"
            [class]="col.styleClass"
            [ngClass]="{
              highlighted: col.highlighted
            }"
            *ngFor="let col of columns; let i = index"
            [class.sticky-column]="i < 3"
          >
            {{ col.label | translate }}
          </th>
          <th *ngIf="buttonTemplate" class="action-cell sticky-column"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ngSwitch]="rowData.itemType" class="approve-report-table__order-row" [ngClass]="rowData.rowStyleClass">
          <td class="toggle-cell sticky-column">
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
          </td>
          <ng-container *ngSwitchCase="ITEM_TYPE.ProductionOrder">
            <td class="tiny-cell sticky-column">
              <i
                class="icon"
                [ngClass]="rowData.kpiStatus === KPI_STATUS.Ok ? 'icon-checked-solid' : 'icon-warning-solid icon--warning'"
              ></i>
            </td>
            <td class="tiny-cell sticky-column">
              <span *ngIf="rowData.hasInfo" class="link__text" (click)="emitInfoEvent(rowData)">{{ 'APPROVE.INFO_LINK' | translate }}</span>
            </td>
            <td class="large-cell sticky-column">{{ rowData.productionOrderIdOrDowntimeType }}</td>
            <td class="large-cell overflow">{{ rowData.customerOrDowntimeReasonLocalId }}</td>
            <td class="small-cell">
              <div *ngIf="rowData.isFinished; else notFinished">{{ 'APPROVE.FINISHED' | translate }}</div>
              <ng-template #notFinished>
                <div>{{ 'APPROVE.NOT_FINISHED' | translate }}</div>
              </ng-template>
            </td>
            <td class="small-cell">{{ rowData.startTime | utcToLocal | date : 'HH:mm' }}</td>
            <td class="small-cell">{{ rowData.endTime | utcToLocal | date : 'HH:mm' }}</td>
            <td class="small-cell">{{ rowData.setupDuration | elapsedTime }}</td>
            <td class="small-cell">{{ rowData.runtimeDuration | elapsedTime }}</td>
            <td class="small-cell">{{ rowData.downtimeDuration | elapsedTime }}</td>
            <td class="medium-cell" [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity : false }}</td>
            <td class="medium-cell" [title]="rowData.wasteQuantity?.value | number">
              {{ rowData.wasteQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell" [title]="rowData.maculatureQuantity?.value | number">
              {{ rowData.maculatureQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell" [title]="rowData.plannedQuantity?.value | number">
              {{ rowData.plannedQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell" [title]="rowData.orderGoodQuantity?.value | number">
              {{ rowData.orderGoodQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell" [title]="rowData.goodQuantityPredecessors?.value | number">
              {{ rowData.goodQuantityPredecessors | formatQuantity : false }}
            </td>
            <td class="large-cell" [title]="rowData.consumption?.value | number">{{ rowData.consumption | formatQuantity : false }}</td>
            <td class="small-cell" [title]="rowData.orderGrossQuantity?.value | number">
              {{ rowData.orderGrossQuantity | formatQuantity : false }}
            </td>
            <td class="medium-cell" [title]="rowData.output1?.value | number">{{ rowData.output1 | formatQuantity : false }}</td>
            <td class="medium-cell" [title]="rowData.output2?.value | number">{{ rowData.output2 | formatQuantity : false }}</td>
          </ng-container>

          <ng-container *ngSwitchCase="ITEM_TYPE.Downtime">
            <td class="tiny-cell sticky-column">
              <i
                class="icon"
                [ngClass]="rowData.kpiStatus === KPI_STATUS.Ok ? 'icon-checked-solid' : 'icon-warning-solid icon--warning'"
              ></i>
            </td>
            <td class="tiny-cell sticky-column">
              <span *ngIf="rowData.hasInfo" class="link__text" (click)="emitInfoEvent(rowData)">{{ 'APPROVE.INFO_LINK' | translate }}</span>
            </td>
            <td class="large-cell sticky-column">
              {{ 'BACKEND_TYPE.APPROVE_DOWNTIME.' + rowData.productionOrderIdOrDowntimeType | uppercase | translate }}
            </td>
            <td class="large-cell">{{ rowData.customerOrDowntimeReasonLocalId }}</td>
            <td class="small-cell">
              <div *ngIf="rowData.isFinished; else notFinished">{{ 'APPROVE.FINISHED' | translate }}</div>
              <ng-template #notFinished>
                <div>{{ 'APPROVE.NOT_FINISHED' | translate }}</div>
              </ng-template>
            </td>
            <td class="small-cell">{{ rowData.startTime | utcToLocal | date : 'HH:mm' }}</td>
            <td class="small-cell">{{ rowData.endTime | utcToLocal | date : 'HH:mm' }}</td>
            <td class="small-cell">{{ rowData.setupDuration | elapsedTime }}</td>
            <td class="small-cell">{{ rowData.runtimeDuration | elapsedTime }}</td>
            <td class="small-cell">{{ rowData.downtimeDuration | elapsedTime }}</td>
            <td class="medium-cell" [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity : false }}</td>
            <td class="medium-cell" [title]="rowData.wasteQuantity?.value | number">
              {{ rowData.wasteQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell" [title]="rowData.maculatureQuantity?.value | number">
              {{ rowData.maculatureQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell">{{ rowData.description }}</td>
            <td class="large-cell"></td>
            <td class="large-cell"></td>
            <td class="large-cell"></td>
            <td class="small-cell"></td>
            <td class="medium-cell"></td>
            <td class="medium-cell"></td>
          </ng-container>

          <ng-container *ngSwitchCase="ITEM_TYPE.ProductionOrderRunPhase">
            <td class="tiny-cell sticky-column">
              <i
                class="icon"
                [ngClass]="
                  rowData.runPhaseType === PHASE_TYPE.Consumption && rowData.hasViolation
                    ? 'icon-warning-solid icon--warning'
                    : 'icon-checked-solid'
                "
              ></i>
            </td>

            <td class="tiny-cell sticky-column" *ngIf="rowData.runPhaseType === PHASE_TYPE.Consumption">
              <span class="primary-material" *ngIf="rowData.hasViolation && rowData.availableItemsCount !== null">
                <i class="icon icon-pallet"></i>
                <span class="pallet-value">{{ rowData.availableItemsCount || 0 }}</span>
              </span>
            </td>
            <td *ngIf="rowData.runPhaseType === PHASE_TYPE.Setup" class="tiny-cell sticky-column"></td>
            <td *ngIf="rowData.runPhaseType === PHASE_TYPE.Run" class="tiny-cell sticky-column"></td>
            <td *ngIf="rowData.runPhaseType === PHASE_TYPE.Downtime" class="tiny-cell sticky-column"></td>

            <td class="large-cell sticky-column">
              {{ 'BACKEND_TYPE.APPROVE_ORDER_PHASE.' + rowData.runPhaseType | uppercase | translate }}
            </td>
            <td class="large-cell">{{ rowData.externalSetupParameterKindId }}</td>
            <td class="small-cell"></td>
            <td class="small-cell">{{ rowData.startTime | utcToLocal | date : 'HH:mm' }}</td>
            <td class="small-cell">{{ rowData.endTime | utcToLocal | date : 'HH:mm' }}</td>
            <td class="small-cell">{{ rowData.runPhaseType === PHASE_TYPE.Setup ? (rowData.duration | elapsedTime) : '' }}</td>
            <td class="small-cell">{{ rowData.runPhaseType === PHASE_TYPE.Run ? (rowData.duration | elapsedTime) : '' }}</td>
            <td class="small-cell">{{ rowData.runPhaseType === PHASE_TYPE.Downtime ? (rowData.duration | elapsedTime) : '' }}</td>
            <td class="medium-cell" [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity : false }}</td>
            <td class="medium-cell" [title]="rowData.wasteQuantity?.value | number">
              {{ rowData.wasteQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell" [title]="rowData.maculatureQuantity?.value | number">
              {{ rowData.maculatureQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell"></td>
            <td class="large-cell"></td>
            <td class="large-cell"></td>
            <td class="large-cell"></td>
            <td class="small-cell"></td>
            <td class="medium-cell"></td>
            <td class="medium-cell"></td>
          </ng-container>

          <ng-container *ngSwitchCase="ITEM_TYPE.ConsumptionArticle">
            <td class="tiny-cell sticky-column"></td>
            <td class="tiny-cell sticky-column"></td>
            <td class="large-cell sticky-column overflow">{{ rowData.externalArticleId }}</td>
            <td [colSpan]="3" class="overflow">{{ rowData.articleDescription?.articleName }}</td>
            <td class="small-cell overflow">{{ rowData.articleDescription?.externalConfigurationId }}</td>
            <td [colSpan]="3" class="overflow">{{ rowData.articleDescription?.configurationName }}</td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell" [title]="rowData.totalConsumption?.value | number">
              {{ rowData.totalConsumption | formatQuantity : false }}
            </td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
          </ng-container>

          <ng-container *ngSwitchCase="ITEM_TYPE.ConsumptionMaterial">
            <td class="tiny-cell sticky-column"></td>
            <td class="tiny-cell sticky-column"></td>
            <td class="tiny-cell sticky-column">{{ rowData.sequence }}</td>
            <td class="tiny-cell overflow" *ngIf="rowData.ssccCode" colspan="4"><span [innerHtml]="rowData.ssccCode | sscc"></span></td>
            <td class="tiny-cell overflow" *ngIf="rowData.virtualContainerIdentCode" colspan="4">
              <span [innerHtml]="rowData.virtualContainerIdentCode | sscc"></span>
            </td>
            <td class="small-cell">{{ rowData.externalArticleId }}</td>
            <td class="small-cell">{{ rowData.configurationId }}</td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell" [title]="rowData.consumed?.value | number">{{ rowData?.consumed | formatQuantity : false }}</td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
            <td class="small-cell"></td>
          </ng-container>

          <ng-container *ngSwitchCase="ITEM_TYPE.DowntimeDetail">
            <td class="tiny-cell sticky-column overflow">{{ rowData.shiftName }}</td>
            <td class="tiny-cell sticky-column">
              <span *ngIf="rowData.hasInfo" class="link__text" (click)="emitInfoEvent(rowData)">{{ 'APPROVE.INFO_LINK' | translate }}</span>
            </td>
            <td class="large-cell sticky-column">{{ 'BACKEND_TYPE.APPROVE_DOWNTIME.' + rowData.downtimeType | uppercase | translate }}</td>
            <td>{{ rowData.downtimeReasonLocalId }}</td>
            <td class="small-cell"></td>
            <td class="small-cell">{{ rowData.startTime | utcToLocal | date : 'HH:mm' }}</td>
            <td class="small-cell">{{ rowData.endTime | utcToLocal | date : 'HH:mm' }}</td>
            <td colspan="2"></td>
            <td class="small-cell">{{ rowData.duration | elapsedTime }}</td>
            <td class="medium-cell" [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity : false }}</td>
            <td class="medium-cell" [title]="rowData.wasteQuantity?.value | number">
              {{ rowData.wasteQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell" [title]="rowData.maculatureQuantity?.value | number">
              {{ rowData.maculatureQuantity | formatQuantity : false }}
            </td>
            <td colspan="7">{{ rowData.description }}</td>
          </ng-container>

          <ng-container *ngSwitchCase="ITEM_TYPE.RunDetail">
            <td class="tiny-cell sticky-column overflow">{{ rowData.shiftName }}</td>
            <td class="tiny-cell sticky-column">
              <span *ngIf="rowData.hasInfo" class="link__text" (click)="emitInfoEvent(rowData)">{{ 'APPROVE.INFO_LINK' | translate }}</span>
            </td>
            <td class="large-cell sticky-column">{{ 'BACKEND_TYPE.APPROVE_DOWNTIME.' + rowData.downtimeType | uppercase | translate }}</td>
            <td>{{ rowData.downtimeReasonLocalId }}</td>
            <td class="small-cell"></td>
            <td class="small-cell">{{ rowData.startTime | utcToLocal | date : 'HH:mm' }}</td>
            <td class="small-cell">{{ rowData.endTime | utcToLocal | date : 'HH:mm' }}</td>
            <td class="small-cell"></td>
            <td class="small-cell">{{ rowData.duration | elapsedTime }}</td>
            <td class="small-cell"></td>
            <td class="medium-cell" [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity : false }}</td>
            <td class="medium-cell" [title]="rowData.wasteQuantity?.value | number">
              {{ rowData.wasteQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell" [title]="rowData.maculatureQuantity?.value | number">
              {{ rowData.maculatureQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell">{{ rowData.description }}</td>
            <td class="large-cell"></td>
            <td class="large-cell"></td>
            <td class="large-cell"></td>
            <td class="small-cell"></td>
            <td class="medium-cell"></td>
            <td class="medium-cell"></td>
          </ng-container>

          <ng-container *ngSwitchCase="ITEM_TYPE.SetupDetail">
            <td class="tiny-cell sticky-column overflow">{{ rowData.shiftName }}</td>
            <td class="tiny-cell sticky-column">
              <span *ngIf="rowData.hasInfo" class="link__text" (click)="emitInfoEvent(rowData)">{{ 'APPROVE.INFO_LINK' | translate }}</span>
            </td>
            <td class="large-cell sticky-column">{{ 'BACKEND_TYPE.APPROVE_DOWNTIME.' + rowData.downtimeType | uppercase | translate }}</td>
            <td>{{ rowData.downtimeReasonLocalId }}</td>
            <td class="small-cell"></td>
            <td class="small-cell">{{ rowData.startTime | utcToLocal | date : 'HH:mm' }}</td>
            <td class="small-cell">{{ rowData.endTime | utcToLocal | date : 'HH:mm' }}</td>
            <td class="small-cell">{{ rowData.duration | elapsedTime }}</td>
            <td colspan="2"></td>
            <td class="medium-cell" [title]="rowData.goodQuantity?.value | number">{{ rowData.goodQuantity | formatQuantity : false }}</td>
            <td class="medium-cell" [title]="rowData.wasteQuantity?.value | number">
              {{ rowData.wasteQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell" [title]="rowData.maculatureQuantity?.value | number">
              {{ rowData.maculatureQuantity | formatQuantity : false }}
            </td>
            <td class="large-cell">{{ rowData.description }}</td>
            <td class="large-cell"></td>
            <td class="large-cell"></td>
            <td class="large-cell"></td>
            <td class="small-cell"></td>
            <td class="medium-cell"></td>
            <td class="medium-cell"></td>
          </ng-container>

          <td *ngIf="buttonTemplate" class="action-cell sticky-column">
            <ng-container *ngTemplateOutlet="buttonTemplate; context: { $implicit: rowData, rowNode: rowNode }"></ng-container>
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
  </section>
</ng-container>
<ng-template #noDataTemplate>
  <div class="centered-text">{{ 'APP.NO_DATA_FOUND' | translate }}</div>
</ng-template>
