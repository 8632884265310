import { Injectable } from '@angular/core';
import { TaskAssignModalComponent } from '@app/shared/modals/task-assign-modal';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-mini';
import { AssignReasonToDowntimeBehaviourType, Downtime, DowntimeReason, DowntimeSection } from 'chronos-core-client';
import { DowntimeViewData } from '@app/shared/modals/task-assign-modal/models';
import { WorkCenterTask } from 'projects/chronos-core-client/src/lib/api/models';

@Injectable({
  providedIn: 'root'
})
export class TaskAssignationService {
  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  private headerLength = 85;

  public openAssignTaskModal(
    downtime: Downtime,
    downtimeViewData: DowntimeViewData,
    filterOrderRelated: boolean,
    assignReasonToDowntimeBehaviourType: AssignReasonToDowntimeBehaviourType,
    workCenterId?: number,
    taskList?: WorkCenterTask[]
  ): DynamicDialogRef {
    const downtimeViewDataCurrent: DowntimeViewData = JSON.parse(JSON.stringify(downtimeViewData));
    downtimeViewDataCurrent.reasonTreeList = this.filterCategoryGroups(downtimeViewDataCurrent.reasonTreeList, filterOrderRelated);
    downtimeViewDataCurrent.reasonFlatList = this.filterAutoCompleteReasons(downtimeViewDataCurrent.reasonFlatList, filterOrderRelated);
    const downTimeIds = [];
    let isCheckboxCommand = true;

    taskList?.forEach((x) => {
      if (x.isCheck) {
        downTimeIds?.push(x.downtime.id);
      }
    });

    if (downTimeIds.length <= 0) {
      isCheckboxCommand = false;
      downTimeIds.push(downtime.id);
    }

    return this.dialogService.open(TaskAssignModalComponent, {
      header: this.formModalHeader(taskList, downtime, downTimeIds),
      height: isCheckboxCommand === true ? '760px' : '740px',
      data: {
        downtimeViewData: downtimeViewDataCurrent,
        downtime,
        workCenterId,
        downTimeIds,
        taskList,
        isCheckboxCommand,
        assignReasonToDowntimeBehaviourType
      }
    });
  }

  private filterAutoCompleteReasons(autoCompleteReasons: DowntimeReason[], filterOrderRelated: boolean): DowntimeReason[] {
    return autoCompleteReasons.filter((reason) => this.filterReason(reason, filterOrderRelated));
  }

  private filterReason(reason: DowntimeReason, filterOrderRelated: boolean): boolean {
    if (filterOrderRelated) {
      return !reason.isProductionRelated && reason.isSelectable;
    } else {
      return reason.isSelectable;
    }
  }

  private filterCategoryGroups(categoryGroups: DowntimeSection[], filterOrderRelated: boolean): DowntimeSection[] {
    return categoryGroups.filter((section) => {
      section.categories = section.categories.filter((category) => {
        category.reasons = category.reasons.filter((reason) => this.filterReason(reason, filterOrderRelated));
        return category.reasons.length > 0;
      });
      return section.categories.length > 0;
    });
  }

  private formModalHeader(taskList: WorkCenterTask[], downTime: Downtime, downTimeIds): string {
    if (downTimeIds.length === 1) {
      return this.createSingleDowntimeHeader(downTime);
    } else {
      return this.createMultipleDowntimeHeader(taskList);
    }
  }

  private createSingleDowntimeHeader(downTime: Downtime): string {
    let header = `${this.translateService.instant('TASKS.ASSIGN_DOWNTIME')} ${this.getLocalDateTimeString(downTime.startTime)}`;
    header += downTime.endTime ? ` - ${this.getLocalDateTimeString(downTime.endTime)}` : '';
    header += downTime.externalProductionOrderId ? ` | ${downTime.externalProductionOrderId}` : '';
    return header;
  }

  private createMultipleDowntimeHeader(taskList: WorkCenterTask[]): string {
    const taskListHeader: string[] = [];
    const titleName = `${this.translateService.instant('TASKS.ASSIGN_DOWNTIME')}`;
    taskList.forEach((task) => {
      if (task.isCheck) {
        let header = `${this.getLocalDateTimeString(task.downtime.startTime)}`;
        header += task.downtime.endTime ? ` - ${this.getLocalDateTimeString(task.downtime.endTime)}` : '';
        header += task.downtime.externalProductionOrderId ? ` | ${task.downtime.externalProductionOrderId}` : '';
        taskListHeader?.push(header);
      }
    });

    const firstRecord = taskListHeader[0];
    taskListHeader[0] = `${titleName} ${firstRecord}`;
    const headerText: string = taskListHeader.toString();

    return headerText.length > this.headerLength ? `${headerText.substr(0, this.headerLength)}...` : headerText;
  }

  private getLocalDateTimeString(date: string): string {
    return moment.utc(date).local().format('DD.MM.YYYY HH:mm');
  }
}
