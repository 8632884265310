import { Injectable } from '@angular/core';
import { ActiveOrderDsService } from '@app/core/data-services';
import { ActiveOrderQuery } from '@app/core/global-state';
import { ManualQaCheckEntry, QaCheckType, SignatureConfig } from '@app/shared/models/signature';
import { QaCheckService, RunSubPhaseType } from 'chronos-core-client';
import { AppSettingsQuery } from 'chronos-shared';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignatureHandlerService {
  private resetSignatureSource = new Subject<number>();
  resetSignature$ = this.resetSignatureSource.asObservable();

  private signatureIsPresentSource = new BehaviorSubject<boolean>(false);
  signatureIsPresent$ = this.signatureIsPresentSource.asObservable();

  private qadataSource = new BehaviorSubject<ManualQaCheckEntry>(null);
  sharedQaInputData = this.qadataSource.asObservable();

  constructor(
    private appSettingsQuery: AppSettingsQuery,
    private activeOrderDsService: ActiveOrderDsService,
    private qaCheckSerice: QaCheckService,
    private activeOrderQuery: ActiveOrderQuery
  ) {}

  /**
   * Reset the signature
   * @param value
   */
  public resetSignature(): void {
    this.resetSignatureSource.next();
  }

  /**
   * Check if the signature is present
   * @param value
   */
  public signatureIsPresent(value: boolean): void {
    this.signatureIsPresentSource.next(value);
  }

  /**
   * Check if the signature is present
   * @returns
   */
  public isSignaturePresent(): boolean {
    let value: boolean;
    this.signatureIsPresent$.subscribe((val) => (value = val)).unsubscribe();
    return !!value;
  }

  /**
   * Check if the signature is enabled
   * @returns
   */
  public isQualityCheckSignatureEnabled(): boolean {
    let value: boolean;
    this.enableQualityCheckSignature()
      .subscribe((val) => (value = val))
      .unsubscribe();
    return !!value;
  }

  /**
   * Set read only mode for QA check and signature when signature is enabled
   */
  public setReadOnlyState(isLineClearance?: boolean): boolean {
    const runPhaseType = this.activeOrderQuery.getActiveOrderSubPhase();
    const isLineClearancePhase = runPhaseType === RunSubPhaseType.SetupLineClearance;
    const isSetupQaCheckPhase = runPhaseType === RunSubPhaseType.SetupInProgressFinishingCheck;

    return isLineClearance ? !isLineClearancePhase : !isSetupQaCheckPhase;
  }

  /**
   * Reload the QA checks
   * @param signatureConfig
   * @returns void
   */
  public reloadQaChecks(signatureConfig: SignatureConfig): void {
    const qaCheckType = signatureConfig.qaCheckType;
    const productionOrderId = this.activeOrderDsService.getActiveOrderId();
    switch (qaCheckType) {
      case QaCheckType.Setup:
        this.qaCheckSerice.getSetupProductionQaChecks({ productionOrderId }).subscribe();
        break;
      case QaCheckType.Container:
        this.qaCheckSerice.getQaChecksForContainer({ productionOrderId }).subscribe();
        break;
      case QaCheckType.Automatic:
        this.qaCheckSerice.getActivePeriodicCheck({ productionOrderId }).subscribe();
        break;
      case QaCheckType.Periodic:
        this.qaCheckSerice.getManualProductionQaChecks({ productionOrderId }).subscribe();
        break;
      default:
        break;
    }
  }

  /**
   * Set the shared data
   * @param data
   */
  public storeSharedData(data: any) {
    this.qadataSource.next(data);
  }

  /**
   * Check if the signature is enabled
   * @returns boolean
   */
  private enableQualityCheckSignature(): Observable<boolean> {
    return this.appSettingsQuery.enableQualityCheckSignature;
  }
}
