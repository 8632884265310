import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ButtonItem } from '@app/shared/components/split-button/split-button.model';
import { ManualMachineMode, Quantity } from 'chronos-core-client';
import { notificationTopic } from '@app/shared/utils';

@Component({
  selector: 'app-output-quantity-pool',
  templateUrl: './output-quantity-pool.component.html',
  styleUrls: ['./output-quantity-pool.component.scss']
})
export class OutputQuantityPoolComponent implements OnChanges {
  @Input() public grossQuantity: Quantity;
  @Input() public manualMachineMode: ManualMachineMode;
  @Input() public manualCreation = false;
  @Input() public showButtons = true;
  @Input() public isCancelProductionOrder = false;
  @Input() public isManualCheckAvailable;
  @Input() public isGluing = false;
  @Input() public slitCount = 1;
  @Output() public createPalletClicked = new EventEmitter<void>();
  @Output() public changePalletQuantityClicked = new EventEmitter<void>();
  @Output() public cancelProductionOrderClicked = new EventEmitter<void>();
  @Output() public openPeriodicQaCheckClicked = new EventEmitter<void>();
  @Output() public openChangeBobbinQuantityClicked = new EventEmitter<void>();

  public readonly LOADING_TOPIC = notificationTopic.quantityPoolAction;
  public poolButtons: ButtonItem[];

  public ngOnChanges(changes: SimpleChanges): void {
    this.poolButtons = this.AddButtonsToPool(changes);
  }

  public onCreatePalletClicked(): void {
    this.createPalletClicked.emit();
  }

  public changePalletQuantity(): void {
    this.changePalletQuantityClicked.emit();
  }

  public onCancelProductionOrder(): void {
    this.cancelProductionOrderClicked.emit();
  }

  public onOpenPeriodicQaCheckClicked(): void {
    this.openPeriodicQaCheckClicked.emit();
  }

  public onChangeBobbinQuantityClicked(): void {
    this.openChangeBobbinQuantityClicked.emit();
  }

  private AddButtonsToPool(changes: SimpleChanges): ButtonItem[] {
    if (
      (changes.grossQuantity || changes.manualMachineMode || changes.isCancelProductionOrder || changes.isManualCheckAvailable) &&
      !this.isGluing
    ) {
      return this.buildPoolButtons();
    }

    if (changes.isManualCheckAvailable) {
      return this.buildGluingMachineButtons();
    }
  }

  private buildPoolButtons(): ButtonItem[] {
    return this.manualMachineMode === ManualMachineMode.Cutting ? this.buildManualCuttingButtons() : this.buildQuantityPoolSplitButton();
  }

  private buildQuantityPoolSplitButton(): ButtonItem[] {
    return [
      {
        command: () => {
          this.onCreatePalletClicked();
        },
        label: 'PALLET_CARD.CREATE_PALLET',
        primary: this.grossQuantity.value > 0,
        disabled: this.grossQuantity.value === 0,
        visible: this.manualCreation
      },
      {
        command: () => {
          this.changePalletQuantity();
        },
        label: 'PALLET_CARD.CHANGE_QTY_PER_PALLET',
        primary: this.grossQuantity.value === 0
      },
      {
        command: () => {
          this.onCancelProductionOrder();
        },
        label: 'MANUAL_CANCEL_MODAL.CANCEL_PRODUCTION_ORDER',
        primary: this.isCancelProductionOrder,
        disabled: !this.isCancelProductionOrder
      },
      {
        command: () => {
          this.onOpenPeriodicQaCheckClicked();
        },
        label: 'RUN_PHASE.PERIODIC_QA_CHECK',
        primary: this.isCancelProductionOrder,
        disabled: !this.isManualCheckAvailable,
        visible: true
      },
      {
        command: () => this.onChangeBobbinQuantityClicked(),
        label: 'RUN_PHASE.CHANGE_BOBBIN_QTY',
        visible: this.slitCount > 1
      }
    ];
  }

  private buildManualCuttingButtons(): ButtonItem[] {
    return [
      {
        command: () => {
          this.onCreatePalletClicked();
        },
        label: 'PALLET_CARD.CREATE_PALLET',
        primary: this.grossQuantity.value > 0,
        disabled: this.grossQuantity.value === 0
      },
      {
        command: () => {
          this.onCancelProductionOrder();
        },
        label: 'MANUAL_CANCEL_MODAL.CANCEL_PRODUCTION_ORDER',
        primary: this.isCancelProductionOrder,
        disabled: !this.isCancelProductionOrder
      },
      {
        command: () => {
          this.onOpenPeriodicQaCheckClicked();
        },
        label: 'RUN_PHASE.PERIODIC_QA_CHECK',
        primary: this.isCancelProductionOrder,
        disabled: !this.isManualCheckAvailable,
        visible: true
      }
    ];
  }

  private buildGluingMachineButtons(): ButtonItem[] {
    return [
      {
        command: () => {
          this.onOpenPeriodicQaCheckClicked();
        },
        label: 'RUN_PHASE.PERIODIC_QA_CHECK',
        primary: true,
        disabled: !this.isManualCheckAvailable,
        visible: true
      }
    ];
  }
}
