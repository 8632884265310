import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TaskAssignationService } from '@app/core/services';
import { ButtonItem, ChartMode, IMachineChartDialogConfiguration, MachineChartDialogComponent } from '@app/shared/components';
import { DialogService } from 'primeng/dynamicdialog';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { taskStatusFlags } from '@app/shared/models';
import { AssignReasonToDowntimeBehaviourType, WorkCenterTask, WorkCenterTaskStatus } from 'chronos-core-client';
import { DowntimeViewData } from '@app/shared/modals/task-assign-modal/models';
import { LoadingNotificationService, ModalConfirmComponent } from 'chronos-shared';
import { TranslateService } from '@ngx-translate/core';
import { DowntimeDsService, TaskDsService } from '@app/core/data-services';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { notificationTopic } from '@app/shared/utils';
import { ActiveOrderQuery } from '@app/core/global-state';

@Component({
  selector: 'app-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss']
})
export class TaskItemComponent implements OnChanges {
  public selectedTaskItems: WorkCenterTask[];
  @Input() public taskItem?: WorkCenterTask;
  @Input() public taskList?: WorkCenterTask[];
  @Input() public isHighlightTask = false;
  @Input() public downtimeViewData: DowntimeViewData;
  public downTimeIds: number[];
  @Output() public itemSelected = new EventEmitter<WorkCenterTask>();
  @Output() public itemChecked = new EventEmitter<WorkCenterTask>();

  public readonly TASK_STATUS_FLAGS = taskStatusFlags;
  public readonly TASK_STATUS = WorkCenterTaskStatus;
  public isItemCollapsed = true;
  public taskButtons: ButtonItem[] = [];
  public loadingTopic: string;
  public checkBox = false;
  public isTaskChecked = false;
  public selectedDownTimeIds = [];

  constructor(
    private taskAssignationService: TaskAssignationService,
    private dialogService: DialogService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private translateService: TranslateService,
    private taskDsService: TaskDsService,
    private downTimeDsService: DowntimeDsService,
    private activeOrderQuery: ActiveOrderQuery
  ) {
    this.selectedTaskItems = new Array<WorkCenterTask>();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.taskItem?.currentValue) {
      this.taskButtons = this.constructTaskButtons(this.checkTaskSelection());
      this.loadingTopic = notificationTopic.convertToProductionAction + this.taskItem.downtime.id;
    }
  }

  private constructTaskButtons(isItemCheckedFlag?: boolean): ButtonItem[] {
    const isAssignDowntimeCodeEnabled = !this.taskItem?.downtime?.isAssignDowntimeCodeEnabled;
    const hasEndTime = !!this.taskItem?.downtime?.endTime;
    const hasGrossQuantity = this.taskItem?.downtime?.grossQuantity.value > 0;
    let singleSelection = false;

    if (isItemCheckedFlag) {
      singleSelection = !(this.taskItem.isCheck === true);
      isItemCheckedFlag = false;
    }

    return [
      {
        command: () => {
          this.showDowntimeModal();
        },
        label: 'TASKS.ASSIGN_TASK',
        disabled: isAssignDowntimeCodeEnabled || isItemCheckedFlag || singleSelection,
        visible: !(isAssignDowntimeCodeEnabled && hasEndTime && hasGrossQuantity)
      },
      {
        command: () => {
          this.openMachineChart();
        },
        label: 'TASKS.SPLIT',
        disabled: !this.taskItem?.downtime?.isSplitDowntimeEnabled
      },
      {
        command: () => {
          this.openConvertToProduction();
        },
        label: 'TASKS.CONVERT_TO_PRODUCTION',
        visible: hasGrossQuantity,
        disabled: !this.taskItem?.downtime?.isConvertToProductionEnabled
      },
      {
        command: () => {
          this.openEditDowntime();
        },
        label: 'TASKS.EDIT_DOWNTIME',
        disabled: !this.taskItem?.downtime?.isEditDowntimeEnabled
      }
    ];
  }

  public toggleContent(): void {
    this.isItemCollapsed = !this.isItemCollapsed;
  }

  public onItemSelected(): void {
    if (this.isTaskChecked) {
      this.isTaskChecked = false;
    } else {
      this.itemSelected.emit(this.taskItem);
    }
  }

  public onItemChecked(): void {
    this.isTaskChecked = true;
    this.itemChecked.emit(this.taskItem);
  }

  private openMachineChart(): void {
    if (this.taskItem?.downtime) {
      this.dialogService.open(MachineChartDialogComponent, {
        width: '75%',
        styleClass: 'no-scroll-dialog',
        header: this.translateService.instant('MACHINE_CHART.DOWNTIME_CHART_TITLE'),
        data: {
          workCenterId: this.activeWorkCenterService.getWorkCenterId(),
          chartMode: ChartMode.SplitDowntime,
          startTime: new Date(this.taskItem.downtime.startTime),
          endTime: this.taskItem.downtime.endTime === null ? null : new Date(this.taskItem.downtime.endTime),
          downTimeId: this.taskItem.downtime.id
        } as IMachineChartDialogConfiguration
      });
    } else {
      throw new Error(`TaskItemComponent.openMachineChart: downtime = ${typeof this.taskItem?.downtime}`);
    }
  }

  private showDowntimeModal(): void {
    if (this.taskItem?.downtime) {
      this.taskAssignationService.openAssignTaskModal(
        this.taskItem.downtime,
        this.downtimeViewData,
        !this.taskItem.downtime.hasRunAssigned,
        AssignReasonToDowntimeBehaviourType.Task,
        this.taskItem?.workCenterId,
        this.taskList
      );
    } else {
      throw new Error(`TaskItemComponent.showDowntimeModal: downtime = ${typeof this.taskItem?.downtime}`);
    }
  }

  private checkTaskSelection(): boolean {
    return this.taskList.some((task) => task.isCheck);
  }

  private openConvertToProduction(): void {
    if (this.taskItem?.downtime) {
      this.dialogService
        .open(ModalConfirmComponent, {
          header: this.translateService.instant('TASKS.CONVERT_TO_PRODUCTION'),
          data: {
            question: this.translateService.instant('TASKS.CONVERT_TO_PRODUCTION_QUESTION'),
            acceptable: true
          }
        })
        .onClose.pipe(
          filter((isSubmit) => !!isSubmit),
          tap(() => {
            LoadingNotificationService.publish(this.loadingTopic, true);
          }),
          finalize(() => {
            LoadingNotificationService.publish(this.loadingTopic, false);
          }),
          switchMap(() => this.taskDsService.convertToProduction(this.taskItem.downtime.id))
        )
        .subscribe();
    } else {
      throw new Error(`TaskItemComponent.openConvertToProduction: downtime = ${typeof this.taskItem?.downtime}`);
    }
  }

  // open edit downtime dialog
  private openEditDowntime(): void {
    if (this.taskItem?.downtime) {
      const downTimeId = this.taskItem.downtime.id;
      this.downTimeDsService.getEditDowntimeStatus(downTimeId).subscribe((editDowntimeStatus) => {
        if (editDowntimeStatus) {
          this.dialogService.open(MachineChartDialogComponent, {
            width: '75%',
            styleClass: 'no-scroll-dialog',
            header: this.translateService.instant('MACHINE_CHART.EDIT_DOWNTIME_CHART_TITLE'),
            data: {
              workCenterId: this.activeWorkCenterService.getWorkCenterId(),
              chartMode: ChartMode.EditDowntime,
              startTime: new Date(editDowntimeStatus.startTime),
              endTime: new Date(editDowntimeStatus.endTime),
              latestEndTime: new Date(editDowntimeStatus.latestEndTime),
              downTimeId: editDowntimeStatus.id,
              quantity: editDowntimeStatus.grossQuantity,
              startCounter: editDowntimeStatus.startCounter,
              endCounter: editDowntimeStatus.endCounter
            } as IMachineChartDialogConfiguration
          });
        }
      });
    } else {
      throw new Error(`TaskItemComponent.openEditDowntime: downtime = ${typeof this.taskItem?.downtime}`);
    }
  }
}
