<section class="approve">
  <div class="page-padding">
    <div class="header">
      <div class="header__page-title">{{ 'APPROVE.TITLE' | translate }}</div>
      <div class="header__title">{{ 'APPROVE.TECHNOLOGY' | translate }}</div>
      <lib-dropdown
        class="header__input"
        [inlineStyle]="{ width: '300px' }"
        [options]="technologyOptions$ | async"
        [(ngModel)]="selectedTechnology"
        (ngModelChange)="onTechnologyChanged()"
      ></lib-dropdown>
      <div class="header__title">{{ 'APPROVE.PROFILE_DAY' | translate }}</div>
      <lib-calendar
        class="header__input"
        [(ngModel)]="selectedDate"
        (ngModelChange)="onDateChanged()"
        [showTime]="false"
        [maxDate]="maxDate"
        [inputStyle]="{ width: '100px' }"
        [dayTemplate]="calendarDayTemplate"
        [styleClass]="calendarStyleClass"
        (monthChanged)="updateCalendarStyleClass($event)"
        [selectOtherMonths]="true"
      ></lib-calendar>
      <div *ngIf="openDates" class="header__input" [ngClass]="{ 'header__input--danger': openDates.length > 0 }">
        {{ 'APPROVE.OPEN_DAYS' | translate : { openDaysCount: openDates.length } }}
      </div>
    </div>
    <div class="content">
      <div class="content__select-area">
        <p-selectButton
          styleClass="chronos-select-button-dark"
          [options]="approvalWorkCenterOptions"
          [(ngModel)]="selectedApprovalWorkCenter"
          (ngModelChange)="onWorkCenterChanged()"
          unselectable="false"
        >
          <ng-template let-item pTemplate>
            <span class="select-button__label">{{ item.label }}</span>
            <span class="select-button__icon"><i [ngClass]="item.title"></i></span>
          </ng-template>
        </p-selectButton>

        <span *ngIf="approveReportData?.hasInfo" class="link__text" (click)="showShiftInfo()">{{ 'APPROVE.SHIFT_INFO' | translate }}</span>
      </div>

      <div class="kpis">
        <ng-container *ngFor="let approveHeaderKpi of approveReportData?.kpiItems" [ngSwitch]="approveHeaderKpi.kpiIdentifier">
          <app-status-panel
            *ngSwitchCase="KPI_IDENTIFIER.KpiOrders"
            class="kpis__item"
            [label]="'APPROVE_REPORT.KPI_ORDERS' | translate"
            [value]="approveHeaderKpi.value.value + ' ' + ('APPROVE_REPORT.OF' | translate) + ' ' + approveHeaderKpi.target.value"
            [height]="'small'"
          ></app-status-panel>
          <app-status-panel
            *ngSwitchCase="KPI_IDENTIFIER.GoodQuantity"
            class="kpis__item kpis__item--wide-3"
            [label]="'APPROVE_REPORT.KPI_GOOD_QUANTITY' | translate"
            [value]="(approveHeaderKpi.value | formatQuantity : false : 0) + ' / ' + (approveHeaderKpi.target | formatQuantity : false : 0)"
            [height]="'tiny'"
            [textStyle]="'tiny'"
          ></app-status-panel>
          <app-status-panel
            *ngSwitchCase="KPI_IDENTIFIER.Downtime"
            class="kpis__item kpis__item--wide-2"
            [label]="'APPROVE_REPORT.KPI_DOWNTIME' | translate"
            [value]="approveHeaderKpi.valueTime | elapsedTime"
            [height]="'tiny'"
            [textStyle]="'tiny'"
          ></app-status-panel>
          <app-status-panel
            *ngSwitchCase="KPI_IDENTIFIER.Waste"
            class="kpis__item"
            [label]="'APPROVE_REPORT.KPI_WASTE' | translate"
            [value]="approveHeaderKpi.value | formatQuantity : false : 0"
            [height]="'tiny'"
            [textStyle]="'tiny'"
          ></app-status-panel>
          <app-status-panel
            *ngSwitchCase="KPI_IDENTIFIER.Output1"
            class="kpis__item kpis__item--wide-1"
            [label]="'APPROVE_REPORT.OUTPUT1' | translate"
            [value]="approveHeaderKpi.value | formatQuantity : true : 0"
            [height]="'tiny'"
            [textStyle]="'tiny'"
          ></app-status-panel>
          <app-status-panel
            *ngSwitchCase="KPI_IDENTIFIER.Output2"
            class="kpis__item kpis__item--wide-1"
            [label]="'APPROVE_REPORT.OUTPUT2' | translate"
            [value]="approveHeaderKpi.value | formatQuantity : true : 0"
            [height]="'tiny'"
            [textStyle]="'tiny'"
          ></app-status-panel>
          <app-status-panel
            *ngSwitchCase="KPI_IDENTIFIER.ConsumptionPrimary"
            class="kpis__item kpis__item--wide-3"
            [label]="'APPROVE_REPORT.CONSUMPTION_PRIMARY' | translate"
            [value]="approveHeaderKpi.value | formatQuantity : true : 0"
            [height]="'tiny'"
            [textStyle]="'tiny'"
          ></app-status-panel>
          <app-status-panel
            *ngSwitchCase="KPI_IDENTIFIER.BookedHours"
            class="kpis__item kpis__item--wide-1"
            [label]="'APPROVE_REPORT.BOOKED_HOURS' | translate"
            [value]="(approveHeaderKpi.valueTime | elapsedTime : true) + ' / ' + (approveHeaderKpi.targetTime | elapsedTime : true)"
            [height]="'tiny'"
            [textStyle]="'tiny'"
            [status]="approveHeaderKpi.hasViolation ? 'warning' : 'success'"
          ></app-status-panel>
          <app-status-panel
            *ngSwitchCase="KPI_IDENTIFIER.SetupTime"
            class="kpis__item kpis__item--wide-2"
            [label]="'APPROVE_REPORT.SETUP_TIME' | translate"
            [value]="approveHeaderKpi.valueTime | elapsedTime : false"
            [height]="'tiny'"
            [textStyle]="'tiny'"
          ></app-status-panel>
          <app-status-panel
            *ngSwitchCase="KPI_IDENTIFIER.Absence"
            class="kpis__item"
            [label]="'APPROVE_REPORT.ABSENCE' | translate"
            [value]="approveHeaderKpi.valueTime | elapsedTime : false"
            [height]="'tiny'"
            [textStyle]="'tiny'"
          ></app-status-panel>
          <app-status-panel
            *ngSwitchCase="KPI_IDENTIFIER.EmployeeRegistration"
            class="kpis__item cursor-clickable kpis__item--wide-1"
            [label]="'APPROVE_REPORT.EMPLOYEE_REGISTRATION' | translate"
            [value]="approveHeaderKpi.value.value"
            [height]="'tiny'"
            [textStyle]="'tiny'"
            (click)="onEmployeeRegistrationKPIClick()"
          ></app-status-panel>
        </ng-container>
      </div>

      <app-approve-report-table
        [tableData]="approveReportData?.items"
        [buttonTemplate]="buttonTemplate"
        (infoLinkClicked)="showAdditionalItemInfo($event)"
        (loadNodeClicked)="loadNode($event)"
        (tableExpandClicked)="expandTable($event)"
      ></app-approve-report-table>
    </div>
  </div>
  <footer *ngIf="approveReportData?.items.length > 0" class="footer">
    <ng-container *ngIf="approveReportData?.isConfirmedByUser; else confirmReport">
      <div class="footer__link">
        <i class="link__icon icon-info-solid"></i>
        <span>{{ 'APPROVE.CONFIRMED' | translate }}</span>
      </div>
    </ng-container>
    <ng-template #confirmReport>
      <ng-container *ngIf="approveReportData.enableSendingApprovalReport; else approveDisabled">
        <ng-container *ngIf="approveReportData.confirmSendingApprovalReport; else sendApproveReport">
          <app-setup-footer
            [nextCommand]="confirmSendApproveReportCommand"
            [confirmFirstCheckboxCommand]="confirmInformationCommand"
          ></app-setup-footer>
        </ng-container>
        <ng-template #sendApproveReport>
          <app-setup-footer
            [nextCommand]="sendApproveReportCommand"
            [confirmFirstCheckboxCommand]="confirmInformationCommand"
          ></app-setup-footer>
        </ng-template>
      </ng-container>
      <ng-template #approveDisabled>
        <div class="footer__link">
          <span>{{ 'APPROVE.PROFILE_DAY_DISABLED' | translate }}</span>
        </div>
      </ng-template>
    </ng-template>
  </footer>
</section>

<ng-template #calendarDayTemplate let-date>
  <span [ngClass]="getCalendarDateStyleClass(date)">{{ date.day }}</span>
</ng-template>

<ng-template #buttonTemplate let-rowData let-rowNode="rowNode">
  <ng-container *ngIf="!approveReportData?.isConfirmedByUser && rowData.isEditable">
    <ng-container *ngIf="rowData.downtimeType === DOWNTIME_TYPE.Downtime && rowData.itemType !== ITEM_TYPE.SetupDetail">
      <app-split-button class="content__split-button" [appendTo]="'body'" [items]="constructDowntimeActions(rowData)"></app-split-button>
    </ng-container>
    <ng-container *ngIf="rowData.itemType === ITEM_TYPE.ProductionOrder">
      <app-split-button class="content__split-button" [appendTo]="'body'" [items]="constructOrderActions(rowData)"></app-split-button>
    </ng-container>
    <ng-container *ngIf="rowData.itemType === ITEM_TYPE.ProductionOrderRunPhase && rowData.runPhaseType === PHASE_TYPE.Setup">
      <app-split-button
        *ngIf="constructSetupActions(rowData) as buttons"
        class="content__split-button"
        [appendTo]="'body'"
        [items]="buttons"
      ></app-split-button>
    </ng-container>
    <ng-container *ngIf="rowData.itemType === ITEM_TYPE.ProductionOrderRunPhase && rowData.runPhaseType === PHASE_TYPE.Run">
      <app-split-button
        *ngIf="constructRunActions(rowData) as buttons"
        class="content__split-button"
        [appendTo]="'body'"
        [items]="buttons"
      ></app-split-button>
    </ng-container>
    <ng-container *ngIf="rowData.itemType === ITEM_TYPE.ConsumptionArticle && rowData.materialType === MATERIAL_TYPE.Primary">
      <app-split-button
        class="content__split-button"
        [appendTo]="'body'"
        [items]="constructConsumptionPrimaryActions(rowData, rowNode)"
      ></app-split-button>
    </ng-container>
    <ng-container *ngIf="rowData.itemType === ITEM_TYPE.ConsumptionArticle && rowData.materialType === MATERIAL_TYPE.Secondary">
      <app-split-button
        class="content__split-button"
        [appendTo]="'body'"
        [items]="constructConsumptionSecondaryActions(rowData, rowNode)"
      ></app-split-button>
    </ng-container>
    <ng-container *ngIf="rowData.itemType === ITEM_TYPE.ConsumptionMaterial">
      <i (click)="onDismountClick(rowData, rowNode)" class="content__dismount-button icon-unmount-pallet"></i>
    </ng-container>
  </ng-container>
</ng-template>
