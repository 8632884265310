import { Injectable } from '@angular/core';
import { WorkCenterDsService, ActiveWorkCenterService } from '@app/core/workcenter';
import { AppSettingsQuery, ListValue } from 'chronos-shared';
import { Observable } from 'rxjs';
import { ManualMachineMode, QaCheck, QaCheckService, WorkCenterProductionMode } from 'chronos-core-client';

@Injectable()
export class OutputContainerListService {
  constructor(
    private workCenterDsService: WorkCenterDsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private qaCheckService: QaCheckService,
    private appSettingsQuery: AppSettingsQuery
  ) {}

  public getQaSetupValues(productionOrderId: number): Observable<QaCheck[]> {
    return this.qaCheckService.getQaChecksForContainer({ productionOrderId });
  }

  public getReasonOptions(): Observable<ListValue[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    return this.workCenterDsService.getReasonOptions(workCenterId);
  }

  public getProductionMode(): Observable<WorkCenterProductionMode> {
    return this.appSettingsQuery.workCenterProductionMode$;
  }

  public getManualMachineMode(): Observable<ManualMachineMode> {
    return this.appSettingsQuery.manualMachineMode$;
  }

  public isManualModeForContainer(): Observable<boolean> {
    return this.appSettingsQuery.isManualModeForContainer$;
  }
}
