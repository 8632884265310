import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TaskDsService } from '@app/core/data-services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  AssignReasonToDowntimeBehaviourType,
  AssignReasonToDowntimeEntry,
  Downtime,
  DowntimeCategory,
  DowntimeReason
} from 'chronos-core-client';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { notificationTopic } from '@app/shared/utils';
import { isNil } from 'ramda';
import { TaskAssignModalService } from './task-assign-modal.service';
import { DowntimeViewData } from '@app/shared/modals/task-assign-modal/models';
import { Observable } from 'rxjs';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
  selector: 'app-task-assign-modal',
  templateUrl: './task-assign-modal.component.html',
  styleUrls: ['./task-assign-modal.component.scss']
})
export class TaskAssignModalComponent implements OnInit, AfterViewInit {
  public readonly LOADING_TOPIC = notificationTopic.modalAssignDowntime;

  public reasonInfo: string;
  public selectedCategoryGroup: number;
  public selectedCategory: number;
  public selectedWasteReason: number;
  private workCenterId?: number;

  public downtimeViewData: DowntimeViewData;
  public downtime: Downtime;
  public filteredCategories: DowntimeCategory[];
  public filteredReasons: DowntimeReason[];
  public reasons: DowntimeReason[];
  public reasonAutoCompleteModel: DowntimeReason;
  public assignReasonToDowntimeEntry: AssignReasonToDowntimeEntry;
  public downTimeIds: any;
  public waste = 0;
  public wasteUnitId: string;
  public maculature = 0;
  public maculatureUnitId: string;
  public checkBoxClicked = false;
  public isCheckBoxCommand: boolean;
  private behaviourType: AssignReasonToDowntimeBehaviourType;

  public taskListLength$ = new Observable();

  @ViewChild('reasonAutoComplete') reasonAutoComplete: AutoComplete;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private taskDsService: TaskDsService,
    private taskAssignModalService: TaskAssignModalService
  ) {}

  public ngOnInit(): void {
    this.downtimeViewData = this.config.data.downtimeViewData;
    this.downtime = this.config.data.downtime;
    this.selectedWasteReason = this.downtime.wasteReasonId;
    this.workCenterId = this.config.data.workCenterId;
    this.downTimeIds = this.config.data.downTimeIds;
    this.reasonInfo = this.downtime.info;
    this.isCheckBoxCommand = this.config.data?.isCheckboxCommand;
    this.behaviourType = this.config.data?.assignReasonToDowntimeBehaviourType;

    if (this.isCheckBoxCommand) {
      this.config.data.taskList.forEach((item) => {
        if (item.isCheck) {
          this.waste = this.waste + (item.downtime.wasteQuantity?.value ?? 0);
          this.maculature = this.maculature + (item.downtime.maculatureQuantity?.value ?? 0);
          this.wasteUnitId = item.downtime.wasteQuantity?.unitId;
          this.maculatureUnitId = item.downtime.maculatureQuantity?.unitId;
        }
      });
    } else {
      this.waste = this.downtime.wasteQuantity?.value ?? 0;
      this.maculature = this.downtime.maculatureQuantity?.value ?? 0;
      this.wasteUnitId = this.downtime.wasteQuantity?.unitId;
      this.maculatureUnitId = this.downtime.maculatureQuantity?.unitId;
    }

    if (this.downtime.reasonId) {
      this.reasonAutoCompleteModel = this.downtimeViewData.reasonFlatList.find((reason) => reason.id === this.downtime.reasonId);
      this.onAutoCompleteReasonSelect(this.reasonAutoCompleteModel);
    }
  }

  // after version 17 upgrade make autofocus changes working as now focus is on close button of dyanmic dialog
  // using native element to focus on input field now as focusInput menthod is not working this is temporay fix
  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.reasonAutoComplete.el.nativeElement.firstChild.firstChild.focus();
    }, 300);
  }

  public filterAutoCompleteReasons(event): void {
    this.reasons = this.downtimeViewData.reasonFlatList.filter((reason) =>
      reason.description.toLowerCase().includes(event.query.toLowerCase())
    );
  }

  public onCategoryGroupSelect(categoryGroupId: number): void {
    this.filteredReasons = null;
    this.selectedCategory = null;

    this.selectedCategoryGroup = categoryGroupId;
    this.filteredCategories = this.downtimeViewData.reasonTreeList.find((category) => category.id === categoryGroupId).categories;
  }

  public onCategorySelect(categoryId: number, isCategorySelectedManually = true): void {
    if (isCategorySelectedManually) {
      this.reasonAutoCompleteModel = null;
    }
    this.selectedCategory = categoryId;
    this.filteredReasons = this.filteredCategories.find((reason) => reason.id === categoryId).reasons;
  }

  public onReasonSelect(reason: DowntimeReason): void {
    this.reasonAutoCompleteModel = reason;
    this.recalculateWasteMaculature(reason.isProductionRelated);
  }

  public onAutoCompleteReasonSelect(selectedReason: DowntimeReason): void {
    const selection = this.taskAssignModalService.getDowntimeFlatSelect(this.downtimeViewData.reasonTreeList, selectedReason);

    if (selection.categoryGroupId && selection.categoryId) {
      this.onCategoryGroupSelect(selection.categoryGroupId);
      this.onCategorySelect(selection.categoryId, false);
      this.recalculateWasteMaculature(selectedReason.isProductionRelated);
    } else {
      this.clearTreeSelection();
    }
  }

  public clearTreeSelection(): void {
    this.selectedCategory = null;
    this.selectedCategoryGroup = null;
    this.filteredReasons = null;
  }

  public isButtonDisabled(): boolean {

    if (this.waste === 0 && !this.isDowntimeInfoFieldRequired()) {
      return !this.filteredReasons || !this.reasonAutoCompleteModel;
    }
  
    if (!this.isWasteAssigned() && !this.selectedWasteReason) {
      return true;
    }
  
    if (!(this.reasonAutoCompleteModel?.isSelectable ?? this.downtime?.isSelectable) && this.commentCheckForNonSelectableDowntime()) {
      return false;
    }
  
    return !(this.reasonAutoCompleteModel && !this.isDowntimeInfoFieldRequired() && this.isWasteAssigned());
  }

  public setCheckBox(event) {
    this.checkBoxClicked = event;
  }

  public isDowntimeInfoFieldRequired(): boolean {
    if (!(this.reasonAutoCompleteModel?.isSelectable ?? this.downtime?.isSelectable) && !this.commentCheckForNonSelectableDowntime())
      return true;

    return !this.reasonInfo && this.reasonAutoCompleteModel && this.reasonAutoCompleteModel.isCommentMandatory;
  }

  public submitReason(): void {
    const assignMultipleDowntimeResponse: AssignReasonToDowntimeEntry = {
      reasonId: this.reasonAutoCompleteModel?.id ?? this.downtime.reasonId,
      info: this.reasonInfo,
      maculature: { value: this.maculature, unitId: this.maculatureUnitId },
      waste: { value: this.waste, unitId: this.wasteUnitId },
      wasteReasonId: this.selectedWasteReason,
      workCenterId: this.workCenterId,
      behaviourType: this.behaviourType
    };

    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.taskDsService
      .setMultipleDownTime(this.workCenterId, this.downTimeIds, assignMultipleDowntimeResponse, this.behaviourType)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.ref.close(true);
      });
  }

  public closeModal(): void {
    this.ref.close(false);
  }

  public isDowntimeReasonRequired(): boolean {
    return this.downtime.isSelectable === true ? !this.selectedCategoryGroup : this.downtime.isSelectable;
  }

  private isWasteAssigned(): boolean {
    return this.waste === 0 || !isNil(this.selectedWasteReason);
  }

  private commentCheckForNonSelectableDowntime() {
    if (this.downtime?.isCommentMandatory && this.reasonInfo?.length > 0) return true;
    if (!this.downtime?.isCommentMandatory) return true;
  }

  private recalculateWasteMaculature(isProductionRelated: boolean): void {
    if (isProductionRelated) {
      this.waste += this.maculature;
      this.maculature = 0;
    } else {
      this.maculature += this.waste;
      this.waste = 0;
      this.selectedWasteReason = null;
    }
  }
}
