import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActiveOrderDsService, RemarkDsService, ShiftReportDsService, TaskDsService } from '@app/core/data-services';
import { ShiftReportItem, ShiftReportOpenTask } from '@app/core/global-state';
import { HubUrlsService } from '@app/core/utils';
import { ConfirmInformationCommand, SendCompleteReportCommand, SendReportCommand } from '@app/modules/shift-report/commands';
import { GroupedDropdownItem, GroupedDropdownSubItem } from '@app/modules/shift-report/models';
import { TranslateService } from '@ngx-translate/core';
import {
  KpiValue,
  ProductionOrderStatus,
  Remark,
  Shift,
  ShiftReportItemType,
  ShiftReportStatus,
  WebSocketClientService
} from 'chronos-core-client';
import * as R from 'ramda';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, share, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class ShiftReportService {
  constructor(
    private translateService: TranslateService,
    private shiftReportDsService: ShiftReportDsService,
    private webSocketClientService: WebSocketClientService,
    private hubUrlsService: HubUrlsService,
    private activeOrderDsService: ActiveOrderDsService,
    private taskDsService: TaskDsService,
    private remarkDsService: RemarkDsService
  ) {}

  public confirmInformationCommand: ConfirmInformationCommand;
  public sendReportCommand: SendReportCommand;
  public sendCompleteReportCommand: SendCompleteReportCommand;

  private selectedShiftIdSubject = new Subject<number>();
  public selectedShiftId$ = this.selectedShiftIdSubject.asObservable();
  private selectedShiftReportItems$ = this.selectedShiftId$.pipe(
    switchMap((currentShiftId) => this.shiftReportDsService.getShiftReportItems(currentShiftId)),
    share()
  );
  public selectedShiftReportHeaderKpis$ = this.selectedShiftId$.pipe(
    switchMap((currentShiftId) =>
      this.shiftReportDsService.getHeaderShiftKpis(currentShiftId).pipe(map(R.sort(R.ascend(R.prop('orderSequence')))))
    )
  );
  public selectedShiftOpenTasks$ = this.selectedShiftId$.pipe(
    switchMap((currentShiftId) => this.shiftReportDsService.getShiftOpenTasks(currentShiftId)),
    share()
  );

  public hasUncompletedTask$ = this.selectedShiftOpenTasks$.pipe(
    map((tasks) => tasks.some((task) => task.openNumberOfTasks && task.hasViolation))
  );
  public downtimeViewData$ = this.taskDsService.getCombinedDowntimeData();

  private selectedShiftId: number;
  private isProductionOrder = (shiftReportItem) => shiftReportItem.type === ShiftReportItemType.ProductionOrder;
  private isDowntime = (shiftReportItem) => shiftReportItem.type === ShiftReportItemType.Downtime;
  private isOpenStatus = (shiftReportItem) => shiftReportItem.status === ProductionOrderStatus.Open;

  public setCommands(
    sendCommand: SendReportCommand,
    sendCompleteCommand: SendCompleteReportCommand,
    confirmInfo: ConfirmInformationCommand
  ): void {
    this.sendReportCommand = sendCommand;
    this.sendCompleteReportCommand = sendCompleteCommand;
    this.confirmInformationCommand = confirmInfo;
  }

  public changeConfirmInfoCommand(checked: boolean): void {
    this.sendReportCommand.enabled = checked;
    this.sendCompleteReportCommand.enabled = checked;
    this.confirmInformationCommand.enabled = checked;
    this.confirmInformationCommand.required = !checked;
  }

  public changeShift(selectedShiftId: number): void {
    this.selectedShiftId = selectedShiftId;
    this.selectedShiftIdSubject.next(selectedShiftId);
    this.changeConfirmInfoCommand(false);
  }

  public getCurrentShift(): Observable<Shift> {
    return this.shiftReportDsService.getShifts().pipe(map((shifts) => shifts[0]));
  }

  public refreshShifts(): void {
    this.changeShift(this.selectedShiftId);
  }

  public isShiftReportAvailable(): Observable<boolean> {
    return this.shiftReportDsService.getShiftReportStatus().pipe(map((status) => status !== ShiftReportStatus.Closed));
  }

  public filterShiftReportOpenItems(): Observable<ShiftReportItem[]> {
    return this.selectedShiftReportItems$.pipe(map(R.filter(R.both(this.isProductionOrder, this.isOpenStatus))));
  }

  public filterShiftReportNotOpenItems(): Observable<ShiftReportItem[]> {
    const isOrderNotOpen = R.both(this.isProductionOrder, R.pipe(this.isOpenStatus, R.not));
    return this.selectedShiftReportItems$.pipe(map(R.filter(R.either(isOrderNotOpen, this.isDowntime))));
  }

  public filterShiftReportItemsByProductionOrder(filterValue: string): void {
    this.shiftReportDsService.filterShiftReportItemsByProductionOrder(filterValue);
  }

  public getSelectedShiftId(): number {
    return this.selectedShiftId;
  }

  public getShiftRemarkId(): number {
    return this.shiftReportDsService.getShiftReportRemarkId();
  }

  public getShiftRemark(remarkId: number): Observable<Remark> {
    return this.remarkDsService.getRemark(remarkId);
  }

  public saveShiftRemark(workCenterId: number, remarkId: number, remarkText: string): Observable<void> {
    return this.remarkDsService.setRemark(workCenterId, remarkId, remarkText);
  }

  public sendShiftReport(shiftId: number, comment: string, printReport: boolean): Observable<null> {
    return this.shiftReportDsService.sendShiftReport(shiftId, comment, printReport);
  }

  public getActiveOrderNotifications(): Observable<any> {
    return combineLatest([this.activeOrderDsService.selectactiveOrderId(), this.selectedShiftId$]).pipe(
      switchMap(([activeOrderId, shiftId]) =>
        this.webSocketClientService.getNotificationsForTopic<ShiftReportItem>(
          this.hubUrlsService.getActiveOrderKpis(activeOrderId, shiftId)
        )
      ),
      tap((shiftReportItem: ShiftReportItem) => {
        this.shiftReportDsService.updateShiftReportItems(shiftReportItem);
      })
    );
  }

  public getHeaderShiftKpisNotifications(): Observable<KpiValue[]> {
    return this.selectedShiftId$.pipe(
      switchMap((shiftId) =>
        this.webSocketClientService.getNotificationsForTopic<KpiValue[]>(this.hubUrlsService.getShiftReportHeaderKpis(shiftId))
      ),
      tap((data) => {
        this.shiftReportDsService.updateHeaderShiftKpis(data);
      })
    );
  }

  public getShiftOpenTasksNotifications(): Observable<ShiftReportOpenTask[]> {
    return this.selectedShiftId$.pipe(
      switchMap((shiftId) =>
        this.webSocketClientService.getNotificationsForTopic<ShiftReportOpenTask[]>(this.hubUrlsService.getShiftReportOpenTasks(shiftId))
      ),
      tap((data) => {
        this.shiftReportDsService.updateShiftOpenTasks(data);
      })
    );
  }

  public getDropDownOptions(): Observable<GroupedDropdownItem[]> {
    return this.selectedShiftId$.pipe(
      switchMap(() =>
        combineLatest([this.shiftReportDsService.getShifts(), this.translateService.stream('SHIFT_REPORT.ENDED')]).pipe(
          map(([shifts, translation]) => shifts.map((shift) => this.mapDropdownShift(shift, translation))),
          map(R.groupBy(R.prop('groupBy'))),
          map(R.toPairs),
          map((items: []) => items.map((item: [string, GroupedDropdownSubItem[]]) => ({ label: item[0], items: item[1] })))
        )
      )
    );
  }

  private mapDropdownShift(shift: Shift, translation: string): GroupedDropdownSubItem {
    let shiftEnded = '';

    if (this.shiftReportDsService.isShiftEnded(shift)) {
      shiftEnded = `(${translation})`;
    }

    return {
      label: `${formatDate(shift.startTime, 'dd.M.yyyy', 'en-us')} - ${shift.shiftTypeName} ${shiftEnded}`,
      value: shift.id,
      groupBy: formatDate(shift.startTime, 'dd.M.yyyy', 'en-us')
    };
  }
}
