import { Injectable } from '@angular/core';
import { QaCheckResult, QaCheckService, SetQaCheckResultEntry, PeriodicQaCheck, CreateManualQaChecksEntry } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { ActiveOrderQuery } from '@app/core/global-state';

@Injectable({
  providedIn: 'root'
})
export class QaCheckDsService {
  constructor(
    private qaCheckService: QaCheckService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private activeOrderQuery: ActiveOrderQuery
  ) {}

  public setAllQaChecksForSetup(body: SetQaCheckResultEntry): Observable<void> {
    return this.qaCheckService.setAllQaChecksForSetup({
      productionOrderId: this.activeOrderQuery.getActiveOrderId(),
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      body
    });
  }

  public setAllQaChecksForPeriodic(periodicQaCheckId: number, qaCheckResult: SetQaCheckResultEntry): Observable<void> {
    return this.qaCheckService.setAllQaChecksForPeriodic({
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      periodicQaCheckId,
      body: qaCheckResult
    });
  }

  public setAllQaChecksForProducedMaterial(producedMaterialId: number, qaCheckResult: SetQaCheckResultEntry): Observable<void> {
    return this.qaCheckService.setAllQaChecksForProducedMaterial({
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      producedMaterialId,
      body: qaCheckResult
    });
  }

  public setCheckResult(
    productionQaCheckId: number,
    qaCheckResult: QaCheckResult,
    qaCheckComment: string,
    qaMeasurementResult: number
  ): Observable<void> {
    const workCenterId: number = this.activeWorkCenterService.getWorkCenterId();
    return this.qaCheckService.setCheckResult({
      workCenterId,
      productionQaCheckId,
      body: {
        checkResult: qaCheckResult,
        comment: qaCheckComment,
        workCenterId,
        measurementValue: qaMeasurementResult
      }
    });
  }

  public getManualProductionQaChecks(productionOrderId: number): Observable<PeriodicQaCheck> {
    return this.qaCheckService.getManualProductionQaChecks({ productionOrderId });
  }

  public getActivePeriodiQaChecks(productionOrderId: number): Observable<PeriodicQaCheck> {
    return this.qaCheckService.getActivePeriodicCheck({ productionOrderId });
  }

  public createManualQaChecks(manualQACheckItems: CreateManualQaChecksEntry): Observable<void> {
    return this.qaCheckService.createManualQaChecks({
      productionOrderId: this.activeOrderQuery.getActiveOrderId(),
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      body: manualQACheckItems
    });
  }
}
