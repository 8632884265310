import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProducedMaterialCheckStatus, WasteAssignmentFormEvent, WasteAssignmentValidations } from '@app/shared/models';
import { ProducedMaterialCheckState, Quantity, WasteAssignment, WasteQuantityMode } from 'chronos-core-client';
import { AppSettingsQuery, ListValue, knownUnits } from 'chronos-shared';
// eslint-disable-next-line id-blacklist
import { any, isNil } from 'ramda';
import { notificationTopic } from '@app/shared/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-waste-assignment-table',
  templateUrl: './waste-assignment-table.component.html',
  styleUrls: ['./waste-assignment-table.component.scss']
})
export class WasteAssignmentTableComponent implements OnInit, OnDestroy {
  public counterUnitId = '';
  public wasteAssignmentUnitId = '';
  private wasteQuantity: Quantity = { value: 0, unitId: this.counterUnitId };
  private maculatureQuantity: Quantity = { value: 0, unitId: this.counterUnitId };

  @Input() public counter: Quantity = { value: 0, unitId: this.counterUnitId };
  @Input()
  public set wasteValue(waste: Quantity) {
    if (this.wasteForm) {
      this.wasteQuantity = { ...waste };
      if (this.wasteValueVar !== waste.value) {
        this.wasteValueVar = waste.value;
        this.wasteForm.get('waste').setValue(waste.value - this.wasteForm.get('maculature').value);
        this.onMaculatureBlur();
      }
      if (!this.counterUnitId) {
        this.setCounterUnit();
      }
    }
  }
  @Input()
  public set maculatureValue(maculature: Quantity) {
    if (this.wasteForm) {
      this.maculatureQuantity = maculature;
      this.wasteForm.get('maculature').setValue(maculature.value);
    }
  }
  @Input() public reasonOptions: ListValue[];

  public wasteAndMaculature: WasteAssignment[];

  @Input()
  public set wasteAndMaculatureValue(wasteAndMaculature: WasteAssignment[]) {
    this.wasteAndMaculature = wasteAndMaculature;

    if (this.wasteForm) {
      this.wasteForm.updateValueAndValidity();
    }
  }
  @Input() public producedMaterialWasteStatus: ProducedMaterialCheckStatus;
  @Input() public palletNumber: number;

  @Output() public rowAdded = new EventEmitter<WasteAssignmentFormEvent>();
  @Output() public rowRemoved = new EventEmitter<number>();
  @Output() public wasteAssigned = new EventEmitter<boolean>();
  @Input() public isSetupWastePhase = false;

  public readonly NUM_FIELD_MIN_VALUE = 0;
  public readonly INPUT_STYLE = { width: '4em', textAlign: 'end' };
  public LOADING_TOPIC = notificationTopic.wasteAssignmentAddAction;

  public wasteForm: UntypedFormGroup;

  public isDataShown = false;
  public errorWasteNotDefined = false;
  public errorWasteOrMaculatureNotDefined = false;
  public errorWasteReasonNotDefined = false;
  public producedMaterialWasteQuantityMode = null;

  private wasteValueVar: number;
  private subscriptions = new Subscription();

  constructor(private builder: UntypedFormBuilder, private appSettingsQuery: AppSettingsQuery) {}

  public ngOnInit(): void {
    this.appSettingsQuery.producedMaterialWasteQuantityMode$.subscribe((value) => (this.producedMaterialWasteQuantityMode = value));
    this.configureWasteForm();
    this.setCounterUnit();
    if (this.palletNumber) {
      this.LOADING_TOPIC = this.LOADING_TOPIC + this.palletNumber;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public toggleData(): void {
    this.isDataShown = !this.isDataShown;
  }

  public trackByFunction(index: number, item: WasteAssignment): number {
    return item.wasteAssignmentId;
  }

  public getTotalMaculature(): Quantity {
    return { value: this.wasteAndMaculature.reduce((a, b) => a + b.maculature?.value, 0), unitId: this.counterUnitId };
  }

  public getTotalWaste(): Quantity {
    return { value: this.wasteAndMaculature.reduce((a, b) => a + b.waste?.value, 0), unitId: this.counterUnitId };
  }

  public hasWasteRecords(): boolean {
    return this.wasteAndMaculature && !!this.wasteAndMaculature.length && this.wasteAndMaculature.some((x) => !x.wasteReasonIdAutoAssigned);
  }

  public getReasonName(reasonOptionId: number | null): string {
    if (reasonOptionId) {
      const matchingReason = this.reasonOptions.find((reason) => reason.value === reasonOptionId);
      return matchingReason ? matchingReason.label : '';
    } else {
      return '';
    }
  }

  public onMaculatureBlur(): void {
    const wasteField = this.wasteForm.get('waste');
    const maculatureField = this.wasteForm.get('maculature');
    this.calculateValuesOnBlur(maculatureField, wasteField, true);

    this.maculatureQuantity.value = maculatureField.value;
    this.maculatureQuantity.unitId = this.counterUnitId;

    if (wasteField.value <= 0) {
      this.wasteQuantity.value = 0;
    }
  }

  public onWasteBlur(): void {
    const wasteField = this.wasteForm.get('waste');
    const maculatureField = this.wasteForm.get('maculature');
    this.calculateValuesOnBlur(wasteField, maculatureField);

    this.wasteQuantity.value = wasteField.value;
    this.wasteQuantity.unitId = this.counterUnitId;

    if (maculatureField.value <= 0) {
      this.maculatureQuantity.value = 0;
    }
  }

  private calculateValuesOnBlur(primaryField: AbstractControl, secondaryField: AbstractControl, isMaculatureBlur = false): void {
    if (!this.producedMaterialWasteStatus) {
      const finalCounter = this.counter.value - primaryField.value - secondaryField.value;

      if (finalCounter < 0 || isMaculatureBlur) {
        let finalSecondaryFieldValue = this.calculateRoundedTotal(secondaryField, finalCounter);

        if (finalSecondaryFieldValue < 0) {
          finalSecondaryFieldValue = 0;
        }

        secondaryField.setValue(finalSecondaryFieldValue);
      }
    }

    if (!primaryField.value) {
      primaryField.setValue(0);
    }
  }

  public addRow(): void {
    if (this.isAddRowDisabled()) {
      return;
    }

    if (!this.wasteAssignmentUnitId) {
      this.wasteAssignmentUnitId = this.counterUnitId;
    }

    const values = this.wasteForm.value;

    this.rowAdded.emit({
      waste: { value: values.waste, unitId: this.counterUnitId },
      maculature: { value: values.maculature, unitId: this.counterUnitId },
      reason: values?.reason
    });

    this.resetWasteAssignment();
  }

  public removeRow(item: WasteAssignment): void {
    this.rowRemoved.emit(item.wasteAssignmentId);
    this.removeWasteAssignUnitId();
  }

  private buildWasteForm(): void {
    this.wasteForm = this.builder.group({
      maculature: [0, [Validators.min(this.NUM_FIELD_MIN_VALUE), Validators.required]],
      waste: [0, [Validators.min(this.NUM_FIELD_MIN_VALUE), Validators.required]],
      reason: [{ value: null, disabled: true }, Validators.required]
    });
  }

  private setFormValidators(): void {
    const validators = [this.validateReasonFilled.bind(this), this.validateRequired.bind(this)];

    if (!this.producedMaterialWasteStatus) {
      validators.push(this.validateWasteOrMaculatureNotDefined.bind(this));
    } else if (this.producedMaterialWasteStatus.state === ProducedMaterialCheckState.Mandatory) {
      validators.push(this.validateIfWasteDefined.bind(this));
    }

    if (this.producedMaterialWasteQuantityMode === WasteQuantityMode.ReduceGoodQuantity) {
      validators.push(this.validateCounterBeingPositive.bind(this));
    }

    this.wasteForm.setValidators(validators);
  }

  private configureWasteForm(): void {
    this.buildWasteForm();
    this.setFormValidators();

    this.subscriptions.add(
      this.wasteForm.get('waste').valueChanges.subscribe((value) => {
        const reasonField = this.wasteForm.get('reason');
        value ? reasonField.enable() : reasonField.disable();
      })
    );

    this.subscriptions.add(
      this.wasteForm.statusChanges.subscribe(() => {
        const wasteValid = this.isWasteEmpty() && this.wasteForm.valid;
        const wasteOrMaculatureNotDefined = this.wasteForm.hasError(WasteAssignmentValidations.wasteOrMaculatureNotDefined);
        this.errorWasteReasonNotDefined = this.wasteForm.hasError(WasteAssignmentValidations.reasonNotDefined);
        this.errorWasteNotDefined = this.wasteForm.hasError(WasteAssignmentValidations.wasteNotDefined);
        this.errorWasteOrMaculatureNotDefined = this.counter.value > 0 && wasteOrMaculatureNotDefined;
        this.wasteAssigned.emit(wasteValid);
      })
    );

    if (this.isWasteDisabled()) {
      this.wasteForm.disable();
    }

    this.wasteForm.updateValueAndValidity();
  }

  private isWasteEmpty(): boolean {
    const waste = this.wasteForm.get('waste').value;
    const maculature = this.wasteForm.get('maculature').value;

    return waste === 0 && maculature === 0;
  }

  public isAddRowDisabled(): boolean {
    const isInvalid = [
      WasteAssignmentValidations.reasonNotDefined,
      WasteAssignmentValidations.counterNegative,
      WasteAssignmentValidations.required,
      WasteAssignmentValidations.wasteOrMaculatureNotDefined
    ].some((error) => this.wasteForm.hasError(error));

    return this.isWasteDisabled() || isInvalid;
  }

  private validateWasteOrMaculatureNotDefined(group: UntypedFormGroup): any {
    if (group) {
      const wasteEmpty = this.isWasteEmpty();

      if (wasteEmpty) {
        return { wasteOrMaculatureNotDefined: true };
      }
    }

    return null;
  }

  private validateIfWasteDefined(): any {
    return !this.hasWasteRecords() ? { wasteNotDefined: true } : null;
  }

  private validateCounterBeingPositive(group: UntypedFormGroup): any {
    if (group) {
      const waste = group.get('waste').value;
      const maculature = group.get('maculature').value;
      const finalCounter = this.counter.value - waste - maculature;

      if (finalCounter < 0) {
        return { counterNegative: true };
      }
    }

    return null;
  }

  private validateReasonFilled(group: UntypedFormGroup): any {
    if (group) {
      const waste = group.get('waste').value;
      const reason = group.get('reason').value;

      if (waste > 0 && isNil(reason)) {
        return { reasonNotDefined: true };
      }
    }
    return null;
  }

  private validateRequired(group: UntypedFormGroup): any {
    if (group) {
      const isAnyFieldRequired = any((control: AbstractControl) => control.hasError('required'));
      const controls = Object.values(group.controls);

      if (isAnyFieldRequired(controls)) {
        return { required: true };
      }

      return null;
    }
  }

  private resetWasteValue(): void {
    this.wasteForm.controls.waste.reset(0);
  }

  private resetMaculatureValue(): void {
    this.wasteForm.controls.maculature.reset(0);
  }

  private resetWasteReasonId(): void {
    this.wasteForm.controls.reason.reset(null);
  }

  private resetWasteAssignment(): void {
    this.resetWasteValue();
    this.resetMaculatureValue();
    this.resetWasteReasonId();
  }

  private isWasteDisabled(): boolean {
    return this.producedMaterialWasteStatus && this.producedMaterialWasteStatus.state === ProducedMaterialCheckState.Disabled;
  }

  private setCounterUnit() {
    if (this.counter && this.counter.unitId) {
      const unitId = this.counter.unitId.toUpperCase();
      this.counterUnitId = unitId;
      this.setWasteAssignUnitId();
    }
  }

  private setWasteAssignUnitId() {
    this.wasteAssignmentUnitId = this.wasteAndMaculature.length > 0 ? this.counterUnitId : '';
  }

  private removeWasteAssignUnitId() {
    this.wasteAssignmentUnitId = this.wasteAndMaculature.length <= 1 ? '' : this.counterUnitId;
  }

  private calculateRoundedTotal(secondaryField: any, finalCounter: number) {
    if (this.counter.unitId.toUpperCase() === knownUnits.SHT || this.counter.unitId.toUpperCase() === knownUnits.PCS) {
      return secondaryField.value + finalCounter;
    } else {
      return Math.round((secondaryField.value + finalCounter) * 100) / 100;
    }
  }
}
