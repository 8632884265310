import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  ShiftReportHeaderKpisQuery,
  ShiftReportHeaderKpisService,
  ShiftReportItem,
  ShiftReportOpenTask,
  ShiftReportOpenTasksQuery,
  ShiftReportOpenTasksService,
  ShiftReportOrdersQuery,
  ShiftReportOrdersService
} from '@app/core/global-state';
import { KpiValue, Shift, ShiftReport, ShiftReportItemType, ShiftReportService, ShiftReportStatus } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ShiftReportDsService {
  constructor(
    private shiftReportService: ShiftReportService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private shiftReportOrdersService: ShiftReportOrdersService,
    private shiftReportOrdersQuery: ShiftReportOrdersQuery,
    private shiftReportHeaderKpisService: ShiftReportHeaderKpisService,
    private shiftReportHeaderKpisQuery: ShiftReportHeaderKpisQuery,
    private shiftReportOpenTasksService: ShiftReportOpenTasksService,
    private shiftReportOpenTasksQuery: ShiftReportOpenTasksQuery
  ) {}

  public getShiftReportItems(shiftId: number): Observable<ShiftReportItem[]> {
    return this.shiftReportService
      .getShiftReport({
        workCenterId: this.activeWorkCenterService.getWorkCenterId(),
        shiftId
      })
      .pipe(
        tap((shiftReport: ShiftReport) => {
          const shiftReportItems = shiftReport.items.map((items) => ({
            ...items,
            shiftReportStatus: shiftReport.shiftReportStatus
          }));

          this.shiftReportOrdersService.setShiftReportOrders(shiftReport, shiftReportItems.map(this.createItemId));
        }),
        switchMap(() => this.shiftReportOrdersQuery.shiftReportOrders$)
      );
  }

  public getShiftReportStatus(): Observable<ShiftReportStatus> {
    return this.shiftReportOrdersQuery.status$;
  }

  public getShiftReportRemarkId(): number {
    return this.shiftReportOrdersQuery.getRemarkId();
  }

  public filterShiftReportItemsByProductionOrder(externalProductionOrderId: string): void {
    this.shiftReportOrdersService.updateFilters({ productionOrder: externalProductionOrderId });
  }

  public updateShiftReportItems(shiftReportItem: ShiftReportItem): void {
    this.shiftReportOrdersService.updateShiftReportOrder(this.createItemId(shiftReportItem));
  }

  public resetShiftReportStore(): void {
    this.shiftReportOrdersService.resetShiftReportOrders();
  }

  public getShifts(): Observable<Shift[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.shiftReportService.getShifts({ workCenterId });
  }

  public isShiftEnded(shift: Shift): boolean {
    return shift.shiftReportStatus === ShiftReportStatus.Closed;
  }

  public getHeaderShiftKpis(shiftId: number): Observable<KpiValue[]> {
    return this.shiftReportService
      .getHeaderShiftKpis({
        workCenterId: this.activeWorkCenterService.getWorkCenterId(),
        shiftId
      })
      .pipe(
        tap((data) => {
          this.shiftReportHeaderKpisService.setShiftReportHeaderKpis(data);
        }),
        switchMap(() => this.shiftReportHeaderKpisQuery.shiftReportHeaderKpis$)
      );
  }

  public updateHeaderShiftKpis(kpis: KpiValue[]): void {
    this.shiftReportHeaderKpisService.updateShiftReportHeaderKpis(kpis);
  }

  public getShiftOpenTasks(shiftId: number): Observable<ShiftReportOpenTask[]> {
    return this.shiftReportService
      .getOpenTasks({
        workCenterId: this.activeWorkCenterService.getWorkCenterId(),
        shiftId
      })
      .pipe(
        tap((data) => {
          const shiftReportOpenTasks = data.map((task) => ({
            type: task.type,
            totalNumberOfTasks: task.totalNumberOfTasks,
            openNumberOfTasks: task.openNumberOfTasks,
            closedNumberOfTasks: task.closedNumberOfTasks,
            hasViolation: task.hasViolation
          }));
          this.shiftReportOpenTasksService.setShiftReportOpenTasks(shiftReportOpenTasks);
        }),
        switchMap(() => this.shiftReportOpenTasksQuery.shiftReportOpenTasks$)
      );
  }

  public updateShiftOpenTasks(openTasks: ShiftReportOpenTask[]): void {
    this.shiftReportOpenTasksService.updateShiftReportOpenTasks(openTasks);
  }

  public sendShiftReport(shiftId: number, openTaskInfo: string, printReport: boolean): Observable<any> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.shiftReportService.sendShiftReport({
      workCenterId,
      body: {
        shiftId,
        openTaskInfo,
        printReport
      }
    });
  }

  private createItemId = (item) => {
    let id = 0;
    if (item.type === ShiftReportItemType.ProductionOrder) {
      id = 27 * item.productionOrder.id + 31 * item.productionOrder.runId;
    } else if (item.type === ShiftReportItemType.Downtime) {
      id = item.downtime.id;
    }
    return { ...item, id: item.type + id };
  };
}
