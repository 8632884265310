import { TabOverviewService } from '@app/modules/mounting/services';
import { Component, OnInit } from '@angular/core';
import { UiStateQuery, UiStateService } from '@app/core/global-state';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BillOfMaterial, MachineSchedule, ProductionOrderStatus } from 'chronos-core-client';
import { LogService } from 'chronos-shared';
import { BillOfMaterialDsService, ProductionOrderDsService } from '@app/core/data-services';
import { ShiftFilterType } from '@app/shared/models';

@Component({
  selector: 'app-tab-overview',
  templateUrl: './tab-overview.component.html',
  styleUrls: ['./tab-overview.component.scss']
})
export class TabOverviewComponent implements OnInit {
  private readonly RELOAD_INTERVAL = 5000;
  private readonly SEARCH_FILTER_CHAR_LENGTH = 3;

  public productionOrder: MachineSchedule;
  public billOfMaterials$: Observable<BillOfMaterial[]>;
  public productionOrders$: Observable<MachineSchedule[]>;
  public initialProductionOrderIndex: number;

  private refreshSidebarEvent = new BehaviorSubject<null>(null);
  private productionOrderIdSubject = new BehaviorSubject<number>(undefined);
  private productionOrderId$ = this.productionOrderIdSubject.asObservable();
  private searchListFilterSubject = new BehaviorSubject<string>('');
  private searchListFilter$: Observable<string> = this.searchListFilterSubject.asObservable();

  constructor(
    private billOfMaterialDsService: BillOfMaterialDsService,
    private productionOrderDsService: ProductionOrderDsService,
    private uiStateQuery: UiStateQuery,
    private uiStateService: UiStateService,
    private tabOverviewService: TabOverviewService
  ) {}

  public ngOnInit(): void {
    this.productionOrders$ = this.refreshSidebarEvent.pipe(
      switchMap(() =>
        combineLatest([
          this.searchListFilter$,
          this.productionOrderDsService.getProductionOrderListByShift(false, ShiftFilterType.All, true)
        ]).pipe(map(([filtervalue, orders]) => this.getFilteredList(orders, filtervalue)))
      ),
      tap((orders) => {
        this.selectActiveOrder(orders);
      })
    );

    this.billOfMaterials$ = this.productionOrderId$.pipe(
      filter((productionOrderId) => !!productionOrderId),
      switchMap((productionOrderId) => this.billOfMaterialDsService.getBillOfMaterials(productionOrderId))
    );
  }

  public onOrderItemSelect(productionOrder: MachineSchedule): void {
    if (productionOrder) {
      this.productionOrder = productionOrder;
      this.productionOrderIdSubject.next(productionOrder.productionOrderId);
      this.uiStateService.setMountingStateSelectedOrderId(productionOrder.productionOrderId);
      this.tabOverviewService.totalContainerCountSubject.next(
        productionOrder.primaryMachineLocationKpi.containerCount + productionOrder.secondaryMachineLocationKpi.containerCount
      );
    }
  }

  public isItemActive(item: MachineSchedule): boolean {
    return item.productionOrderId === this.productionOrder.productionOrderId;
  }

  public refreshSidebarList(refresh: boolean): void {
    if (refresh) {
      this.refreshSidebarEvent.next(null);
    }
  }

  public scanCompletedForOrder(orderList: MachineSchedule[], orderId: number): void {
    if (orderId) {
      const orderItem = orderList.find((item) => item.productionOrderId === orderId);
      LogService.success('SUCCESS_MESSAGE.MATERIAL_MOUNTED_FOR_ORDER', { externalOrderId: orderItem.externalProductionOrderId });
      this.uiStateService.setMountingStateSelectedOrderId(orderItem.productionOrderId);
    } else {
      LogService.success('SUCCESS_MESSAGE.MATERIAL_MOUNTED');
    }
    this.refreshSidebarList(true);
  }

  public searchBarValueChanged(filterValue: string) {
    this.searchListFilterSubject.next(filterValue);
  }

  private getFilteredList(orders: MachineSchedule[], filterValue: string): MachineSchedule[] {
    if (filterValue.length >= this.SEARCH_FILTER_CHAR_LENGTH) {
      return orders.filter((x) => x.externalProductionOrderId.toLowerCase().includes(filterValue.toLowerCase()));
    } else {
      return orders;
    }
  }

  private selectActiveOrder(orders: MachineSchedule[]): void {
    const uiStateOrderId = this.uiStateQuery.getMountingOrderId();
    const activeOrder = uiStateOrderId
      ? orders.find((order) => order.productionOrderId === uiStateOrderId)
      : orders.find((order) => order.status === ProductionOrderStatus.Active);
    const selectedOrder = activeOrder || orders[0];

    if (selectedOrder) {
      this.initialProductionOrderIndex = orders.findIndex((item) => item.productionOrderId === selectedOrder.productionOrderId);
      this.onOrderItemSelect(selectedOrder);
    }
  }
}
