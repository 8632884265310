import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentsComponent } from '@app/modules/documents/containers/documents/documents.component';
import { TabDocumentComponent } from '@app/modules/documents/containers/tab-document/tab-document.component';
import { TabInformationComponent } from '@app/modules/documents/containers/tab-information/tab-information.component';
import { TabLabelComponent } from '@app/modules/documents/containers/tab-label/tab-label.component';
import { EndComponent } from '@app/modules/finish-phase/containers/end/end.component';
import { ToolCheckoutComponent } from '@app/modules/finish-phase/containers/tool-checkout/tool-checkout.component';
import { OverviewComponent } from '@app/modules/finish-phase/containers/overview/overview.component';
import { FinishPhaseComponent } from '@app/modules/finish-phase/finish-phase.component';
import { OrderListComponent } from '@app/modules/orders/containers/order-list/order-list.component';
import { MachineChartComponent } from '@app/modules/machine-chart/machine-chart.component';
import { MountingComponent } from '@app/modules/mounting/containers/mounting/mounting.component';
import { TabOverviewComponent } from '@app/modules/mounting/containers/tab-overview/tab-overview.component';
import { TabPrimaryComponent } from '@app/modules/mounting/containers/tab-primary/tab-primary.component';
import { TabSecondaryComponent } from '@app/modules/mounting/containers/tab-secondary/tab-secondary.component';
import { TabTraceComponent } from '@app/modules/mounting/containers/tab-trace/tab-trace.component';
import { QaLogComponent } from '@app/modules/qa-log/containers/qa-log/qa-log.component';
import { TabQaCheckComponent } from '@app/modules/qa-log/containers/tab-qa-check/tab-qa-check.component';
import { TabQaHistoryComponent } from '@app/modules/qa-log/containers/tab-qa-history/tab-qa-history.component';
import { TabQaNcComponent } from '@app/modules/qa-log/containers/tab-qa-nc/tab-qa-nc.component';
import { RunPhaseComponent } from '@app/modules/run-phase/containers/run-phase/run-phase.component';
import { SetupParametersComponent } from '@app/modules/setup-phase/containers/parameters/setup-parameters.component';
import { SetupQaHistoryComponent } from '@app/modules/setup-phase/containers/qa-history/setup-qa-history.component';
import { QualityAssuranceComponent } from '@app/modules/setup-phase/containers/quality-assurance/quality-assurance.component';
import { SetupPhaseComponent } from '@app/modules/setup-phase/containers/setup-phase/setup-phase.component';
import { WasteAssignmentComponent } from '@app/modules/setup-phase/containers/waste-assignment/waste-assignment.component';
import { ShiftReportComponent } from '@app/modules/shift-report/containers/shift-report/shift-report.component';
import { ShutdownComponent } from '@app/modules/shutdown/shutdown.component';
import { SpeedMonitorHostComponent } from '@app/modules/speed-monitor/speed-monitor-host/speed-monitor-host.component';
import { TaskListComponent } from '@app/modules/task-list/containers/task-list/task-list.component';
import {
  activeOrderMountGuard,
  activeOrderPresentGuard,
  approveGuard,
  finishSubPhaseNavigationGuard,
  manualModeGuard,
  phaseNavigationGuard,
  qaLogGuard,
  qaLogGuardChild,
  resourceConfigurationGuard,
  setupSubPhaseNavigationGuard,
  workCenterGuard
} from '@app/shared/guards';
import { finishingEndSubPhaseNavigationGuard } from '@app/shared/guards/finishing-end-sub-phase-navigation.guard';
import { nav } from '@app/shared/utils';
import { ShellComponent } from '@app/shell/containers/shell/shell.component';
import { ApproveComponent } from '@app/modules/approve/containers/approve/approve.component';
import { EmployeeRegistrationComponent } from '@app/modules/employee-registration/components/employee-registration/employee-registration.component';
import { EmployeeRegistrationHistoryComponent } from '@app/modules/employee-registration/components/history/employee-registration-history/employee-registration-history.component';
import { EmployeeRegistrationLoginComponent } from '@app/modules/employee-registration/components/login/employee-registration-login/employee-registration-login.component';
import { OperationSupportComponent } from '@app/modules/operation-support/containers/operation-support/operation-support.component';
import { TraceabilityComponent } from '@app/modules/operation-support/containers/components/traceability/traceability.component';
import { LineClearanceComponent } from '@app/modules/setup-phase/containers/line-clearance/line-clearance.component';
import { PatternInfoComponent } from '@app/modules/setup-phase/containers/pattern-info/setup-pattern-info.component';
import { ResourceConfigurationComponent } from '@app/modules/resource-configuration/containers/resource-configuration/resource-configuration.component';
import { authorizeGuard } from 'chronos-shared';
import { toolCheckoutGuard } from '@app/shared/guards/tool-checkout.guard';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    canActivate: [authorizeGuard],
    canActivateChild: [workCenterGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: nav.ordersList },
      { path: nav.ordersList, component: OrderListComponent, canActivate: [authorizeGuard] },
      {
        path: nav.setup,
        component: SetupPhaseComponent,
        canActivate: [authorizeGuard, activeOrderPresentGuard, phaseNavigationGuard, manualModeGuard],
        canActivateChild: [authorizeGuard, setupSubPhaseNavigationGuard, manualModeGuard],
        children: [
          {
            path: '',
            redirectTo: nav.parameters,
            pathMatch: 'full'
          },
          {
            path: nav.parameters,
            component: SetupParametersComponent
          },
          {
            path: nav.waste,
            component: WasteAssignmentComponent
          },
          {
            path: nav.quality,
            component: QualityAssuranceComponent
          },
          {
            path: nav.qaHistory,
            component: SetupQaHistoryComponent
          },
          {
            path: nav.lineClearance,
            component: LineClearanceComponent
          },
          {
            path: nav.pattern,
            component: PatternInfoComponent
          }
        ]
      },
      {
        path: nav.run,
        component: RunPhaseComponent,
        canActivate: [authorizeGuard, activeOrderPresentGuard, phaseNavigationGuard]
      },
      {
        path: nav.finish,
        component: FinishPhaseComponent,
        canActivate: [authorizeGuard, activeOrderPresentGuard, phaseNavigationGuard],
        canActivateChild: [authorizeGuard, finishSubPhaseNavigationGuard],
        children: [
          {
            path: '',
            redirectTo: nav.routes.finishOverview,
            pathMatch: 'full'
          },
          {
            path: nav.overview,
            component: OverviewComponent
          },
          {
            path: nav.toolcheckout,
            component: ToolCheckoutComponent,
            canActivate: [toolCheckoutGuard]
          },
          {
            path: nav.end,
            component: EndComponent,
            canActivate: [finishingEndSubPhaseNavigationGuard]
          }
        ]
      },
      { path: nav.tasks, component: TaskListComponent, canActivate: [authorizeGuard] },
      { path: nav.shutdown, component: ShutdownComponent, canActivate: [authorizeGuard] },
      {
        path: nav.mounting,
        component: MountingComponent,
        canActivate: [authorizeGuard],
        canActivateChild: [authorizeGuard],
        children: [
          {
            path: '',
            redirectTo: nav.routes.mountingOverview,
            pathMatch: 'full'
          },
          {
            path: nav.overview,
            component: TabOverviewComponent
          },
          {
            path: nav.primary,
            component: TabPrimaryComponent,
            canActivate: [activeOrderMountGuard]
          },
          {
            path: nav.secondary,
            component: TabSecondaryComponent,
            canActivate: [activeOrderMountGuard]
          },
          {
            path: nav.trace,
            component: TabTraceComponent,
            canActivate: [activeOrderMountGuard]
          }
        ]
      },
      { path: nav.speedMonitorHost, component: SpeedMonitorHostComponent, canActivate: [authorizeGuard] },
      { path: nav.machineChart, component: MachineChartComponent, canActivate: [authorizeGuard] },
      {
        path: nav.documents,
        component: DocumentsComponent,
        canActivate: [authorizeGuard],
        canActivateChild: [authorizeGuard],
        children: [
          {
            path: '',
            redirectTo: nav.routes.information,
            pathMatch: 'full'
          },
          {
            path: nav.information,
            component: TabInformationComponent
          },
          {
            path: nav.files,
            component: TabDocumentComponent
          },
          {
            path: nav.labels,
            component: TabLabelComponent
          }
        ]
      },
      { path: nav.shiftReport, component: ShiftReportComponent, canActivate: [authorizeGuard] },
      {
        path: nav.qaLog,
        component: QaLogComponent,
        canActivate: [authorizeGuard, qaLogGuard],
        canActivateChild: [authorizeGuard, qaLogGuardChild],
        children: [
          {
            path: '',
            redirectTo: nav.qaHistory,
            pathMatch: 'full'
          },
          {
            path: nav.qaChecks,
            component: TabQaCheckComponent
          },
          {
            path: nav.qaNc,
            component: TabQaNcComponent
          },
          {
            path: nav.qaHistory,
            component: TabQaHistoryComponent
          }
        ]
      },
      { path: nav.approve, component: ApproveComponent, canActivate: [approveGuard] },
      {
        path: nav.employeeRegistration,
        component: EmployeeRegistrationComponent,
        children: [
          {
            path: '',
            redirectTo: nav.routes.employeeLogin,
            pathMatch: 'full'
          },
          {
            path: nav.employeeLogin,
            component: EmployeeRegistrationLoginComponent
          },
          {
            path: nav.employeeHistory,
            component: EmployeeRegistrationHistoryComponent
          }
        ]
      },
      {
        path: nav.operationSupport,
        component: OperationSupportComponent,
        canActivate: [authorizeGuard],
        children: [
          {
            path: '',
            redirectTo: nav.routes.traceability,
            pathMatch: 'full'
          },
          {
            path: nav.traceability,
            component: TraceabilityComponent
          }
        ]
      },
      {
        path: nav.resourceConfiguration,
        component: ResourceConfigurationComponent,
        canActivate: [resourceConfigurationGuard]
      }
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShellRoutingModule {}
