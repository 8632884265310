export interface SignatureData {
    scanBadgeText: string;
    hasSignature: boolean;
    employeeId: number;
    hasManualInput: boolean;
    comment: string;
    timeStamp: Date;
    name: string;
}
  
export  interface SignatureState {
    highlight: boolean;
    hasManualInputDisabled: boolean;
    hasScanSignatureResponse: boolean;
}

export interface SignatureConfig {
    isSignatureFeatureEnabled?: boolean;
    isSignaturePresent?: boolean;
    qaCheckType?: QaCheckType;
}

export enum QaCheckType {
    Setup = 'Setup',
    Container = 'Container',
    Periodic = 'Periodic',
    Automatic = 'Automatic',
    LineClearance = 'LineClearance'
}

export interface ManualQaCheckEntry {
    employeeId?: number;
    hasManualInput?: boolean;
    comment?: string;
    timeStamp?: string;
}

export interface Employee {
    employeeId: number;
    externalEmployeeId: string;
    name: string;
}
