<div class="row no-gutters">
  <div *ngIf="collapsible" (click)="toggleContent()" class="print-label-header__toggle">
    <i [ngClass]="{ 'icon-arrows-right-filled-close': !isCollapsed, 'icon-arrow-right-filled-open': isCollapsed }"></i>
  </div>
  <div class="print-label-header__parameters">
    <span class="print-label-header__parameters-title">
      {{
        'OUTPUT_UNIT.PRINTER'
          | translate
            : {
                printer: printerName || '',
                printed: printedLabels,
                total: labelsTotal
              }
      }}
    </span>
    <div *ngIf="labelType === OUTER_LABEL_TYPE.Core || isBobbin; else outerLabel" class="print-label-header__parameters-quantity">
      <div class="print-label-header__parameters-quantity-core" [ngClass]="{ required: !isQuantityPerOuterValid }">
        <lib-input-number
          [(ngModel)]="quantityPerOuter"
          [min]="INPUT_MIN_QUANTITY"
          [max]="INPUT_MAX_QUANTITY"
          [inputStyle]="INPUT_STYLE"
          [disabled]="isPalletFinished"
          (touched)="onQuantityPerOuterChange()"
        ></lib-input-number>
        <span *ngIf="!isBobbin" class="print-label-header__parameters-quantity-label">
          {{ 'OUTPUT_UNIT.QUANTITY_PER_CORE' | translate }}
        </span>
        <span *ngIf="isBobbin" class="print-label-header__parameters-quantity-label">
          {{ 'OUTPUT_UNIT.QUANTITY_PER_BOBBIN' | translate }}
        </span>
      </div>
      <span
        *ngIf="defaultQuantityPerOuter && quantityPerOuter !== defaultQuantityPerOuter"
        class="print-label-header__parameters-quantity-default"
      >
        {{ 'OUTPUT_UNIT.DEFAULT_QUANTITY_PER_OUTER' | translate : { quantity: defaultQuantityPerOuter } }}
      </span>
      <div class="print-label-header__parameters-quantity-core">
        <lib-input-number
          [(ngModel)]="numberOfCopies"
          [inputStyle]="INPUT_STYLE"
          [min]="COPIES_MIN_QUANTITY"
          [max]="COPIES_MAX_QUANTITY"
          [disabled]="isPalletFinished"
          (touched)="onCopiesPerLabelChanged()"
        ></lib-input-number>
        <span class="print-label-header__parameters-copies-label">{{ 'OUTPUT_UNIT.CORE_LABEL_COPIES' | translate }}</span>
      </div>
    </div>
    <a *ngIf="showInactive" [routerLink]="" class="print-label-header__parameters-preview">{{ 'OUTPUT_UNIT.PREVIEW' | translate }}</a>
  </div>
</div>

<ng-template #outerLabel>
  <div class="print-label-header__parameters-quantity">
    <div class="print-label-header__parameters-quantity-core">
      <lib-input-number
        [(ngModel)]="quantityPerOuter"
        [inputStyle]="INPUT_STYLE"
        [min]="INPUT_MIN_QUANTITY"
        [max]="INPUT_MAX_QUANTITY"
        (touched)="onQuantityPerOuterChange()"
      ></lib-input-number>
      <span class="print-label-header__parameters-quantity-label">{{ 'OUTPUT_UNIT.QUANTITY_PER_OUTER' | translate }}</span>
    </div>
    <span
      *ngIf="defaultQuantityPerOuter && quantityPerOuter !== defaultQuantityPerOuter"
      class="print-label-header__parameters-quantity-default"
    >
      {{ 'OUTPUT_UNIT.DEFAULT_QUANTITY_PER_OUTER' | translate : { quantity: defaultQuantityPerOuter } }}
    </span>
  </div>
</ng-template>
