import { Component, OnDestroy, OnInit } from '@angular/core';
import { defaultDropdownValue, DowntimeCodeOptions, TaskQuery, TaskService } from '@app/core/global-state';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { TaskListService } from '../../services/task-list.service';
import { TaskDsService } from '@app/core/data-services';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { ChartType } from 'chronos-shared';
import { WorkCenterTask } from 'chronos-core-client';
import { DowntimeViewData } from '@app/shared/modals/task-assign-modal/models';
import { TaskStore } from '@app/core/global-state/tasks/task.store';
import { FilterDropdown } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit, OnDestroy {
  public downtimeViewData$: Observable<DowntimeViewData>;

  public selectedType? = '';
  public selectedProductionOrder? = '';
  public textQuery? = '';
  public task?: WorkCenterTask;
  public workCenterId$!: Observable<number>;
  public optionsDowntimeCodeAssignation = [
    { value: DowntimeCodeOptions.Open, icon: 'icon-flag-solid' },
    { value: DowntimeCodeOptions.Ok, icon: 'icon-checked-solid' },
    { value: DowntimeCodeOptions.All, label: 'TASK_LIST.ALL_TASKS' }
  ];
  public selectedDowntimeCodeAssignation? = this.optionsDowntimeCodeAssignation[0];
  public chartMode = ChartType.downtimeTask;
  public typeOptions$: Observable<FilterDropdown[]>;
  public productionOrderOptions$: Observable<FilterDropdown[]>;

  private subscriptions = new Subscription();

  constructor(
    private taskService: TaskService,
    public taskQuery: TaskQuery,
    private taskListService: TaskListService,
    private taskDsService: TaskDsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private taskStore: TaskStore,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.workCenterId$ = this.activeWorkCenterService.workCenterId$;
    this.taskService.getAllTasks();
    this.typeOptions$ = this.getTaskOptions(this.taskQuery.typeOptions$);
    this.productionOrderOptions$ = this.getTaskOptions(this.taskQuery.productionOrderOptions$);

    this.subscriptions.add(this.taskListService.getTaskListNotifications().subscribe());

    this.subscriptions.add(
      this.taskQuery.filters$
        .pipe(
          tap((filters) => {
            this.selectedDowntimeCodeAssignation = this.optionsDowntimeCodeAssignation.find(
              (downtimeCode) => downtimeCode.value === filters.downtimeCodeAssignation
            );
            this.selectedType = filters.taskType === 'All' ? defaultDropdownValue : filters.taskType;
            this.selectedProductionOrder = filters.productionOrder === 'All' ? defaultDropdownValue : filters.productionOrder;
            this.textQuery = filters.textQuery;
          })
        )
        .subscribe()
    );

    this.downtimeViewData$ = this.taskDsService.getCombinedDowntimeData();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onFilterChange(): void {
    this.taskService.updateFilters({
      downtimeCodeAssignation: this.selectedDowntimeCodeAssignation.value,
      taskType: this.selectedType,
      productionOrder: this.selectedProductionOrder,
      textQuery: this.textQuery
    });
  }

  public resetFilters(): void {
    this.taskService.resetFilters();
  }

  public trackByFunction(index: number, taskItem: WorkCenterTask): number {
    return taskItem.id;
  }

  public onItemSelected(task: WorkCenterTask): void {
    this.task = task;
  }

  public onItemChecked(task: WorkCenterTask): void {
    let taskList: WorkCenterTask[];

    this.taskQuery.allTasks$.subscribe((list) => {
      taskList = list;
    });

    taskList = taskList.map((arrayElement) => {
      if (arrayElement.downtime.id === task.downtime.id && arrayElement.isCheck !== true) {
        arrayElement = { ...arrayElement, isCheck: true };
      } else if (arrayElement.downtime.id === task.downtime.id && arrayElement.isCheck === true) {
        arrayElement = { ...arrayElement, isCheck: false };
      } else if (arrayElement.downtime.id !== task.downtime.id && arrayElement.isCheck === true) {
        arrayElement = { ...arrayElement, isCheck: true };
      } else {
        arrayElement = { ...arrayElement, isCheck: false };
      }
      return arrayElement;
    });

    taskList.forEach((taskItem) => {
      this.taskService.updateTaskRecord(taskItem);
    });
  }

  public getTaskOptions(latestOption$: Observable<FilterDropdown[]>): Observable<FilterDropdown[]> {
    return combineLatest([latestOption$, this.translateService.onLangChange.pipe(startWith(this.translateService.getDefaultLang()))]).pipe(
      map(([filters]) =>
        filters.map((filter) => {
          const translation = this.translateService.instant(`${filter.label}`);
          return { value: filter.value, label: translation };
        })
      )
    );
  }
}
